import React from 'react';
import './CatagoryCard.css';
import { Link } from 'react-router-dom';
import { baseurl } from '../../../baseUrl';

const CatagoryCard = ({ cat, categoryProps }) => {
    const { title, image_url,image ,slug } = cat;

    
    let uri = `${slug}`;

    return (    
        <div className="col ">
            <Link to={`/products/${uri}`} className="text-decoration-none">
                <div className={"card h-100 cat-card"}>
                    <img src={image_url} className="img-fluid " alt="..." />
                    <div className="card-body">
                        <h5 className="card-title m-0">{title}</h5>
                    </div>
                </div>
            </Link>
        </div >

    );
};

export default CatagoryCard;