import React from 'react';
import './DetailsContent.css';

const DetailsContent = ({ product }) => {

  const data = product?.description;
  return (
    <div className="container-fluid">
      <div className='container py-5 '>
        <div dangerouslySetInnerHTML={{ __html: data }} />
        <img src={product?.image_size_url} alt="" className="d-block m-auto img-fluid" width={"500"} />
      </div>
    </div>
    // <div className='container py-3'>

    //   <ul className="nav nav-pills pb-3 border-bottom" id="pills-tab" role="tablist">
    //     <li className="nav-item" role="presentation">
    //       <button className=" content-btn actives" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Details</button>
    //     </li>
    //     <li className="nav-item" role="presentation">
    //       <button className="content-btn" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Warantly</button>
    //     </li>
    //     <li className="nav-item" role="presentation">
    //       <button className=" content-btn " id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">How to adjust</button>
    //     </li>
    //     <li className="nav-item" role="presentation">
    //       <button className="content-btn " id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Gallery</button>
    //     </li>
    //   </ul>

    //   <div className="tab-content" id="pills-tabContent">
    //     <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    //       <div className='container'>
    //         <div>
    //           <h3>Material</h3>
    //           <p>Suspendisse id malesuada enim eget. Mauris tincidunt posuere tortor volutpat est, molestie feugiat fermentum. Aliquam auctor risus quisque magna adipiscing cursus amet, id sit. Neque ornare sit semper aliquet iaculis massa amet neque ut. Sem rutrum consectetur nulla nulla id integer egestas magna pharetra. Nec orci risus eget in auctor.</p>
    //         </div>
    //         <div>
    //           <h3>GCM</h3>
    //           <p>Amet porta neque aliquet tincidunt rhoncus non. Cursus commodo, et mauris amet, adipiscing ornare a, bibendum. Mi, pellentesque posuere rutrum tellus. Habitant nunc, accumsan viverra gravida venenatis, tellus. Enim, felis amet lectus egestas purus faucibus amet nisi, at. Posuere laoreet euismod eu fermentum donec mi nunc, pharetra..</p>
    //         </div>
    //         <div>
    //           <h3>Quality</h3>
    //           <p>Justo sed gravida quisque fermentum gravida duis. Vitae, eu tristique aliquet viverra vestibulum, enim a facilisis. Odio dolor viverra malesuada tellus auctor arcu sed ipsum habitasse. Sed nunc risus tincidunt sagittis in. Arcu eget malesuada vestibulum, mi, nisl. Pellentesque non vestibulum quis massa enim ultricies..</p>
    //         </div>
    //       </div>


    //     </div>
    //     <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
    //       <div className='container'>
    //         <div>
    //           <h3>Material</h3>
    //           <p>Suspendisse id malesuada enim eget. Mauris tincidunt posuere tortor volutpat est, molestie feugiat fermentum. Aliquam auctor risus quisque magna adipiscing cursus amet, id sit. Neque ornare sit semper aliquet iaculis massa amet neque ut. Sem rutrum consectetur nulla nulla id integer egestas magna pharetra. Nec orci risus eget in auctor.</p>
    //         </div>
    //         <div>
    //           <h3>GCM</h3>
    //           <p>Amet porta neque aliquet tincidunt rhoncus non. Cursus commodo, et mauris amet, adipiscing ornare a, bibendum. Mi, pellentesque posuere rutrum tellus. Habitant nunc, accumsan viverra gravida venenatis, tellus. Enim, felis amet lectus egestas purus faucibus amet nisi, at. Posuere laoreet euismod eu fermentum donec mi nunc, pharetra..</p>
    //         </div>
    //         <div>
    //           <h3>Quality</h3>
    //           <p>Justo sed gravida quisque fermentum gravida duis. Vitae, eu tristique aliquet viverra vestibulum, enim a facilisis. Odio dolor viverra malesuada tellus auctor arcu sed ipsum habitasse. Sed nunc risus tincidunt sagittis in. Arcu eget malesuada vestibulum, mi, nisl. Pellentesque non vestibulum quis massa enim ultricies..</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    //       <div className='container'>
    //         <div>
    //           <h3>Material</h3>
    //           <p>Suspendisse id malesuada enim eget. Mauris tincidunt posuere tortor volutpat est, molestie feugiat fermentum. Aliquam auctor risus quisque magna adipiscing cursus amet, id sit. Neque ornare sit semper aliquet iaculis massa amet neque ut. Sem rutrum consectetur nulla nulla id integer egestas magna pharetra. Nec orci risus eget in auctor.</p>
    //         </div>
    //         <div>
    //           <h3>GCM</h3>
    //           <p>Amet porta neque aliquet tincidunt rhoncus non. Cursus commodo, et mauris amet, adipiscing ornare a, bibendum. Mi, pellentesque posuere rutrum tellus. Habitant nunc, accumsan viverra gravida venenatis, tellus. Enim, felis amet lectus egestas purus faucibus amet nisi, at. Posuere laoreet euismod eu fermentum donec mi nunc, pharetra..</p>
    //         </div>
    //         <div>
    //           <h3>Quality</h3>
    //           <p>Justo sed gravida quisque fermentum gravida duis. Vitae, eu tristique aliquet viverra vestibulum, enim a facilisis. Odio dolor viverra malesuada tellus auctor arcu sed ipsum habitasse. Sed nunc risus tincidunt sagittis in. Arcu eget malesuada vestibulum, mi, nisl. Pellentesque non vestibulum quis massa enim ultricies..</p>
    //         </div>
    //       </div>

    //     </div>
    //   </div>
    // </div>
  );
};

export default DetailsContent;
