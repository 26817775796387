// import Style from './../Style/Style';
import MonthlyDeals from "./MonthyDeals/MonthlyDeals";
// import RecentNews from './RecentNews/RecentNews';
// import MapleSeries from './MapleSeries/MapleSeries';
// import Testimonial from './../Testimonial/Testimonial';
// import Instragram from './../Instagram/Instragram';
// import Footer from './../Shared/Footer/Footer';
import BannerOffer from "./BannerOffer/BannerOffer";
import ProductCatagories from "./ProductCatagories/ProductCatagories";
import WhyRatio from "./WhyRatio/WhyRatio";
import ExclusiveDetails from "../../components/ExclusiveDetails";

const Home = () => {
  return (
    <>
      {/* <Navigation></Navigation> */}
      {/* <Service></Service> */}
      {/* <Navigation></Navigation>   */}
      <BannerOffer></BannerOffer>
      {/* <Banner></Banner> */}
      {/* <Style></Style> */}
      <ExclusiveDetails></ExclusiveDetails>
      <ProductCatagories></ProductCatagories>
      <WhyRatio></WhyRatio>
      <MonthlyDeals></MonthlyDeals>
      {/* <TailorMeDetails></TailorMeDetails> */}

      {/* <RecentNews></RecentNews> */}

      {/* <MapleSeries></MapleSeries> */}
      {/* <Testimonial></Testimonial> */}

      {/* <Instragram></Instragram> */}

      {/* <Footer></Footer> */}
      {/* <Footers></Footers> */}
      {/* <FooterNew></FooterNew> */}
    </>
  );
};

export default Home;
