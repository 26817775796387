import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useDashboard from "../../../Hooks/useDashboard";
import { baseurl } from "../../../baseUrl";
const ProductUpdate = () => {
  const { productId } = useParams();

  const [product, setProduct] = useState({});
  // console.log(product)
  const [catagory, setCatagory] = useState([]);
  const [discount, setDiscount] = useState([]);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const productUpdateHandler = () => {
    const formData = new FormData();
    formData.append("code", product.code);
    formData.append("title", product.title);
    formData.append("price", product.price);
    formData.append("category", product.category);
    formData.append("discount", product.discount);
    formData.append("image", product.image[0]);

    axios
      .patch(`${baseurl}/dashboard/product/${productId}/`, formData)
      .then(function (response) {
        if (response.data.status) {
          Toast.fire({
            icon: "success",
            title: "Product Updated!",
          });
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(`${baseurl}/dashboard/product_details/${productId}/`)
      .then((res) => res.json())
      .then((data) => setProduct(data.data));
  }, [productId]);

  // load category
  useEffect(() => {
    fetch(`${baseurl}/dashboard/category/`)
      .then((res) => res.json())
      .then((data) => setCatagory(data?.data));
  }, []);

  // load discount
  useEffect(() => {
    fetch(`${baseurl}/dashboard/discount/`)
      .then((res) => res.json())
      .then((data) => setDiscount(data?.data));
  }, []);
  return (
    <div className="container py-3 p-4 shadow ">
      <h2 className="py-3 fw-bolder text-secondary card-title">
        Update Your Product
      </h2>

      <form className="row g-3">
        <div className="col-md-6">
          <div className="form-floating ">
            <input
              type="text"
              className="form-control"
              id="code"
              placeholder="code"
              required
              value={product.code || " "}
              onChange={(e) => {
                setProduct({ ...product, code: e.target.value });
              }}
            />
            <label htmlFor="companyName">Code</label>
          </div>
        </div>

        <div className="col-6 ">
          <div className="form-floating">
            <select
              className="form-select"
              id="category"
              aria-label="Floating label select example"
              onChange={(e) =>
                setProduct({ ...product, category: e.target.value })
              }
            >
              {catagory?.map((cat) => (
                <option value={cat.id} selected={cat.id === product.category}>
                  {cat.title}
                </option>
              ))}
            </select>
            <label htmlFor="category">Category</label>
          </div>
        </div>

        <div className="col-6 ">
          <div className="form-floating">
            <select
              className="form-select"
              id="Discount"
              aria-label="Floating label select example"
              onChange={(e) =>
                setProduct({ ...product, discount: e.target.value })
              }
            >
              <option value={"null"} selected={product.discount === null}>
                Not
              </option>
              {discount?.map((dsct) => (
                <>
                  <option
                    value={dsct.id}
                    selected={dsct.id === product.discount}
                  >
                    {dsct.title}
                  </option>
                </>
              ))}
            </select>
            <label htmlFor="Discount">Discount</label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating ">
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="title"
              value={product.title || ""}
              onChange={(e) =>
                setProduct({ ...product, title: e.target.value })
              }
            />
            <label htmlFor="title">Product Title</label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating ">
            <input
              type="text"
              className="form-control"
              id="price"
              placeholder="Price"
              value={product.price || ""}
              onChange={(e) =>
                setProduct({ ...product, price: e.target.value })
              }
            />
            <label htmlFor="price">Price</label>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-floating ">
            <input
              type="file"
              className="form-control"
              id="code"
              placeholder="code"
              required
              onChange={(e) => {
                setProduct({ ...product, image: e.target.files });
              }}
            />
            <label htmlFor="companyName">Image</label>
          </div>
        </div>

        <div className="col-12">
          <button
            type="button"
            className="update-btn"
            onClick={productUpdateHandler}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProductUpdate;
