import React from "react";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { baseurl } from "../../../baseUrl";

const ProductDetails = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState({});
  // console.log(companyDiscount)

  useEffect(() => {
    fetch(`${baseurl}/dashboard/product/${productId}/`)
      .then((res) => res.json())
      .then((data) => setProductDetails(data.data));
  }, [productId]);

  return (
    <div className="container">
      <div className="row py-3 mx-auto">
        <div className=" border mx-auto  shadow-lg rounded p-5">
          <img
            className="img-fluid mx-auto d-block"
            src={productDetails?.image_url}
            alt=""
            width={"100"}
          />

          <div className="py-3">
            <h1 className=" fs-4   company-header">
              Catagory title:{" "}
              <span className="company-names">{productDetails?.title}</span>
            </h1>
            <h1 className=" fs-4   company-header">
              Product code:{" "}
              <span className="company-names">{productDetails?.code}</span>
            </h1>
            <h2 className=" fs-5 py-2  ">
              Product price:{" "}
              <span className="company-name">{productDetails?.price}</span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Discount price:{" "}
              <span className="company-name">
                {productDetails?.discount_price}
              </span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Discount price:{" "}
              <span className="company-name">
                {productDetails?.discount_price}
              </span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Product Description:{" "}
              <span className="company-name">
                {productDetails?.description}
              </span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Product Rating:{" "}
              <span className="company-name">{productDetails?.rating}</span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Order Counter:{" "}
              <span className="company-name">
                {productDetails?.order_counter}
              </span>
            </h2>
            <h2 className=" fs-5 py-2  ">
              Discount Title:{" "}
              <span className="company-name">
                {productDetails?.discount_title}
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
