import React from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "./Appointment.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { baseurl } from "../../../baseUrl";

const Appointment = () => {
  const { tailor_me_id } = useParams();
  // console.log(tailor_me_id);

  const [sending, setSending] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
    setSending(true);
    const formData = new FormData();
    formData.append("product_id", tailor_me_id);
    formData.append("name", data.name);
    formData.append("email_address", data.email_address);
    formData.append("phone_number", data.phone_number);
    formData.append("shipping_address", data.shipping_address);
    formData.append("city", data.city);
    formData.append("country", data.country);
    formData.append("zip_code", data.zip_code);
    formData.append("appointment_date", data.appointment_date);
    formData.append("appointment_time", data.appointment_time);

    axios
      .post(`${baseurl}/dashboard/tailor_me_product_order/`, formData)
      .then(function (response) {
        // console.log(response);
        if (response.data.status) {
          Swal.fire(`${response.data.msg}`, "Welcome Ratio Family", "success");
          reset();
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setSending(false));
  };

  if (sending) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5 min-vh-100">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <Container fluid>
      <Container className="py-3">
        <h2 className="title pt-3">Appointment Page</h2>
        <hr className="hr" />
        <form className="row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-6">
            <div className=" col-md-12 pb-3">
              <label htmlFor="name" className="form-label title-txt">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Ex: S M Aliuzzaman"
                {...register("name", { required: true })}
              />
            </div>

            <div className=" col-md-12 pb-3">
              <label htmlFor="email" className="form-label title-txt">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Ex: aliuzzaman.uiu026@gmail.com"
                {...register("email_address", { required: true })}
              />
            </div>

            <div className=" col-md-12 pb-3">
              <label htmlFor="number" className="form-label title-txt">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="number"
                placeholder="Ex: 01788651774"
                {...register("phone_number", { required: true })}
              />
            </div>
            <div className=" col-md-12 pb-3">
              <label htmlFor="address" className="form-label title-txt">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Ex: Sector-01 , plot-122 , Mohammadpur "
                {...register("shipping_address", { required: true })}
              />
            </div>
            <div className=" col-md-12 pb-3">
              <label htmlFor="country" className="form-label title-txt">
                Country
              </label>
              <select
                name="country"
                id="country"
                className="form-select"
                {...register("country")}
              >
                <option selected>Choose...</option>
                <option value="bangladesh">Bangladesh</option>
              </select>
            </div>
            <div className=" col-md-12 pb-3 d-flex">
              <div className="col-md-6 me-2">
                <label htmlFor="city" className="form-label title-txt">
                  City
                </label>
                <select
                  name="city"
                  id="city"
                  className="form-select"
                  {...register("city")}
                >
                  <option selected>Choose...</option>
                  <option value="dhaka">Dhaka</option>
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="zip-code" className="form-label title-txt">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zip-code"
                  className="form-control"
                  placeholder="Ex: 7400"
                  {...register("zip_code", { required: true })}
                />
              </div>
            </div>
          </div>

          {/* 2nd column */}
          <div className="col-md-6">
            <div className=" col-md-12 pb-3">
              <label htmlFor="date" className="form-label title-txt">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                placeholder="17-05-2022"
                {...register("appointment_date", { required: true })}
              />
            </div>
            <div className=" col-md-12 pb-3">
              <label htmlFor="time" className="form-label title-txt">
                Time
              </label>
              <input
                type="time"
                className="form-control"
                id="time"
                placeholder="12:00pm"
                {...register("appointment_time", { required: true })}
              />
            </div>
          </div>
          <div className="col-12">
            <input
              type="submit"
              className="appointment-submit-button my-5"
              value={"Appointment"}
            />
          </div>
        </form>
      </Container>
    </Container>
  );
};

export default Appointment;
