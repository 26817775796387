import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./PromoCodeCreate.css";
import { baseurl } from "../../../baseUrl";

const PromoCodeCreate = () => {
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    // console.log(data)

    const formData = new FormData();
    formData.append("code", data?.code);
    formData.append("start_date", data?.start_date);
    formData.append("end_date", data?.end_date);
    formData.append("promo_type", data?.promo_type);
    formData.append("minimum_purchase_amount", data?.minimum_purchase_amount);
    formData.append("max_amount", data?.max_amount);
    formData.append("amount", data?.amount);

    axios.post(`${baseurl}/dashboard/promo_code/`, formData).then((res) => {
      // console.log(res)
      if (res.data.status) {
        alert("added succesfully");
        reset();
      } else {
        alert(res.data.msg);
      }
    });
  };

  return (
    <div className="add-service py-3 ">
      <h2 className="py-3 fw-bolder text-secondary card-title">ADD Promo</h2>
      <form
        className="input-type border shadow  row p-5 g-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="code"
              {...register("code")}
              placeholder="Promo code"
            />
            <label htmlFor="code">Promo Code</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating">
            <select
              {...register("promo_type")}
              className="form-select"
              id="Type"
              aria-label="Floating label select example"
            >
              <option value="PERCENTAGE">Percentage</option>
              <option value="AMOUNT">Amount</option>
            </select>
            <label htmlFor="Type">Promo Type</label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="start_date"
              type="date"
              {...register("start_date")}
              placeholder="Start Date"
            />
            <label htmlFor="start_date">Start Date</label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="end_date"
              type="date"
              {...register("end_date")}
              placeholder="End Date"
            />
            <label htmlFor="end_date">End Date</label>
          </div>
        </div>
        {/* <input  {...register("promo_type")} placeholder="Promo Type" /> */}
        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="minimum_purchase_amount"
              type="number"
              {...register("minimum_purchase_amount")}
              placeholder="Minimum Purchase amount"
            />
            <label htmlFor="minimum_purchase_amount">
              Minimum Purchase amount
            </label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="max_amount"
              type="number"
              {...register("max_amount")}
              placeholder="Maximum Amount"
            />
            <label htmlFor="max_amount">Maximum Purchase Amount</label>
          </div>
        </div>

        <div className="col-6">
          <div className="form-floating">
            <input
              className="form-control"
              id="amount"
              type="number"
              {...register("amount")}
              placeholder="Total amount"
            />
            <label htmlFor="amount">Discount amount</label>
          </div>
        </div>

        <div className="col-12">
          <input
            className="update-btn border-0 text-white"
            type="submit"
            value={"Add Promo"}
          />
        </div>
      </form>
    </div>
  );
};

export default PromoCodeCreate;
