import React, { useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { baseurl } from "../../../../baseUrl";

const DeliverdOrder = () => {
  const [deliveredOrder, setDeliveredOrder] = useState([]);
  // console.log(deliveredOrder)
  //is loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/delivered_order_list/`)
      .then((res) => res.json())
      .then((data) => setDeliveredOrder(data.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <Triangle ariaLabel="loading-indicator" />
        Please wait...
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row py-3">
        <h2 className="py-3 fw-bolder text-secondary card-title">
          Delivery Order List
        </h2>

        {deliveredOrder.length === 0 ? (
          <div className="alert alert-primary text-center" role="alert">
            No Delivered Order Available
          </div>
        ) : (
          <div className="col table-responsive">
            <table className="table  table-bordered  rounded shadow-sm  table-hover ">
              <thead className="table-header">
                <tr className="text-center">
                  <th className="py-3" scope="col" width="50">
                    SL
                  </th>
                  <th className="py-3" scope="col">
                    Order No
                  </th>
                  <th className="py-3" scope="col">
                    Customer Name
                  </th>
                  <th className="py-3" scope="col">
                    Payable Amount
                  </th>
                  <th className="py-3" scope="col">
                    Delivery Date
                  </th>
                  <th className="py-3" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {deliveredOrder?.map((ordr, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{ordr?.order_no}</td>
                    <td>{ordr?.customer_info?.name}</td>
                    <td>{ordr?.payable_amount}</td>
                    <td>{ordr?.created_at}</td>
                    <td>
                      <Link to={`/dashboard/orderDetailsView/${ordr.id}`}>
                        <GrView className="text-success fs-5"></GrView>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliverdOrder;
