import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { MdUpdate } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { GrView } from "react-icons/gr";
import { Link } from "react-router-dom";
import { baseurl } from "../../../baseUrl";

const PromoCode = () => {
  const [promoCodeList, setPromoCodeList] = useState([]);

  useEffect(() => {
    fetch(`${baseurl}/dashboard/promo_code/`)
      .then((res) => res.json())
      .then((data) => setPromoCodeList(data.data));
  }, []);

  const handleDeleteOrder = (id) => {
    const url = `${baseurl}/dashboard/promo_code/${id}/`;
    fetch(url, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          alert("deleted successfully");
          const remainingUsers = promoCodeList.filter(
            (promo) => promo.id !== id
          );
          setPromoCodeList(remainingUsers);
        } else {
          alert("Promo Not Deleted", data.msg);
        }
      });
  };

  return (
    <div className="container">
      <div className="row py-3">
        {/* <h3 className="fs-4 mb-3 ">PromoCode</h3> */}

        <div className="col table-responsive">
          <Link to="/dashboard/promoCodeCreate">
            {" "}
            <button type="button" className=" create-button">
              + PromoCode Create
            </button>
          </Link>
          <br />
          <br />
          <table className="table  table-bordered  rounded shadow-sm  table-hover ">
            <thead className="table-header">
              <tr className="text-center">
                <th className="py-3" scope="col" width="50">
                  SL
                </th>
                <th className="py-3" scope="col">
                  Promo Code
                </th>
                <th className="py-3" scope="col">
                  Promo Type
                </th>
                <th className="py-3" scope="col">
                  Discount Amount
                </th>
                <th className="py-3" scope="col">
                  Min amount
                </th>
                <th className="py-3" scope="col">
                  Max amount
                </th>
                <th className="py-3" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {promoCodeList?.map((promoCode, index) => (
                <tr key={index}>
                  <th scope="row">{index}</th>
                  <td>{promoCode?.code}</td>
                  <td>{promoCode?.promo_type}</td>
                  <td>{promoCode?.amount}</td>
                  <td>{promoCode?.minimum_purchase_amount}</td>
                  <td>{promoCode?.max_amount}</td>
                  <td className=" text-center">
                    <Link to={`/dashboard/promoCodeDetails/${promoCode.id}`}>
                      <GrView className="text-success fs-5"></GrView>
                    </Link>

                    <Link to={`/dashboard/promoCodeUpdate/${promoCode.id}`}>
                      <MdUpdate className="mx-2 fs-4"></MdUpdate>
                    </Link>

                    {/* <button onClick={() => handleDeleteOrder(promoCode?.id)}><i className="fas fa-trash"></i></button> */}
                    <TiDelete
                      className=" fs-3 text-danger"
                      role={"button"}
                      onClick={() => handleDeleteOrder(promoCode?.id)}
                    ></TiDelete>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
