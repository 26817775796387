import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Footers from "../../Shared/Footers/Footers";
import loginImg from "../../../images/registration.svg";
import Navigation from "../../Shared/Navigation/Navigation";
import "./Login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import useAuth from "../../../Hooks/useAuth";
import { baseurl } from "../../../baseUrl";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // auth context
  const authInfo = useAuth();
  const { user, setUser, isLoading, setIsLoading } = authInfo;
  // console.log(user);

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    axios
      .post(`${baseurl}/account/login/`, data)
      .then(function (response) {
        // console.log(response.data.data.user.staff_information.is_owner);

        if (response.data.status) {
          // console.log(response.data.data);
          setUser(response.data.data);
          const destination = location?.state?.from || "/";

          if (response.data.data.user.staff_information.is_owner === "True") {
            navigate("/dashboard");
          } else if (
            response.data.data.user.staff_information.is_manager === "True"
          ) {
            navigate("/dashboard");
          } else if (
            response.data.data.user.staff_information.is_moderator === "True"
          ) {
            navigate("/dashboard");
          } else if (
            response.data.data.user.staff_information.is_dzine_staff === "True"
          ) {
            navigate("/home");
          } else {
            navigate("/home");
          }

          // navigate("/dashboard");
          // setUsers(response.data?.data?.auth?.token);
          // let decoded = jwt_decode(response.data?.data?.auth?.token);
          // console.log(decoded);
          Swal.fire(`${response.data.msg}`, "Welcome Ratio Family", "success");
          reset();
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setIsLoading(false));

    // console.log(data)
  };

  return (
    <>
      {/* <Navigation></Navigation> */}
      <div className="container-fluid bg-light">
        <div className="container mx-auto py-3">
          <div className="row d-flex justify-content-center align-items-center p-3">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center">
              <img src={loginImg} alt="" className="img-fluid login-img " />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 border  p-5 shadow-sm bg-white">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  {...register("username")}
                  placeholder="Your E-mail"
                  className="form-control order-process-sub-title-txt "
                />
                <br />
                <input
                  type="password"
                  {...register("password")}
                  placeholder="Password"
                  className="form-control order-process-sub-title-txt "
                />
                <br />
                <input
                  type="submit"
                  value="Login"
                  className="login-register-btn w-100"
                />
              </form>
              <p className="py-3">
                <span className="text-secondary order-process-sub-title-txt">
                  Need account?{" "}
                </span>
                <Link to="/register" className="text-decoration-none">
                  Register here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footers></Footers> */}
    </>
  );
};

export default Login;
