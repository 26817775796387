import React from 'react';
import { useState } from 'react';
import { createContext } from 'react';

export const TailorMeNowDataContext = createContext();

const TailorMeNowInfoProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState({
        name: "",
        email_address: "",
        phone_number: "",
        shipping_address: "",
        city: "",
        country: "",
        zip_code: "",
        product_measurement_info: [

        ]
    })
    return (
        <TailorMeNowDataContext.Provider value={{ userInfo, setUserInfo }}>
            {children}
        </TailorMeNowDataContext.Provider>
    );
};

export default TailorMeNowInfoProvider;