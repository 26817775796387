import React from 'react';
import "./CompanyDetails.css";
import companyLogo from "../../../images/logo/dzinebd.png"
import useDashboard from '../../../Hooks/useDashboard';

const CompanyDetails = () => {

    // dashboard context api
    const allDashboard = useDashboard();
    const { company, setCompany } = allDashboard;

    // console.log(company)
    const { delivery_charge_inside_dhaka, delivery_charge_outside_dhaka, trade_licence_no, packaging_charge, vat_registration_no, name, phone, address, website
    } = company;
    // console.log(company.name)



    return (
        <div className='container'>

            <div className="row py-3 mx-auto">
                <div className=' border mx-auto  shadow-lg rounded p-5'>
                    <img className='img-fluid mx-auto d-block' src={companyLogo} alt="" width={"100"} />

                    <div className='py-3'>
                        <h1 className=' fs-4   company-header'>Company Name: <span className='company-names'>{name}</span></h1>
                        <h2 className=' fs-5 py-2  '>Company Address: <span className='company-name'>{address}</span></h2>
                        <h3 className=' fs-5   company-header'>Phone: <span className='company-others'>{phone}</span></h3>
                        <h3 className=' fs-5 py-2  company-header'>Company Website: <span className='company-others'>{website}</span></h3>
                        <h4 className=' fs-5   company-header'>Delivery charge inside dhaka: <span className=' py-2 company-others'>{delivery_charge_inside_dhaka}</span></h4>
                        <h4 className=' fs-5  company-header py-2'>Delivery charge outside dhaka: <span className='company-others'>{delivery_charge_outside_dhaka}</span></h4>
                        <h4 className='  fs-5  company-header'>Packaging charge: <span className='company-others'>{packaging_charge}</span></h4>
                        <h4 className='fs-5 py-2   company-header'>Trade License: <span className='company-others '>{trade_licence_no}</span></h4>
                        <h4 className='fs-5   company-header'>Vat Registration: <span className='company-others'>{vat_registration_no}</span> </h4>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CompanyDetails;