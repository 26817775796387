import React, { useEffect, useState } from "react";
import CatProductCard from "../ProductContainer/CatProductCard/CatProductCard";
import Footers from "../Shared/Footers/Footers";
import Navigation from "../Shared/Navigation/Navigation";
import { FiFilter } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import axios, { Axios } from "axios";
import { baseurl } from "../../baseUrl";

const SearchShow = () => {
  const { title } = useParams();
  // console.log(title);
  const [catWisePdt, setCatWisePdt] = useState([]);
  // const url = `http://192.168.1.101:5001/dashboard/category_wise_product_list/${slug}/`
  // console.log(catWisePdt);
  // search product api
  axios
    .post(`${baseurl}/dashboard/product_search/`, {
      title,
    })
    .then(function (response) {
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });

  const colorAll = [
    "red",
    "#0A7090",
    "#156008",
    "#219653",
    "#2F80ED",
    "#323334",
    "#4F0E8B",
    "#56CCF2",
    "#6FCF97",
    "#770505",
    "#AAAAAC99",
    "#AD5B12",
    "#BB6BD9",
    "#C4C4C4",
    "#DF1313",
    "#EB5757",
    "#F2994A",
    "#F2C94C",
    "#FFFFFF",
  ];

  const bappi = (color) => {
    //console.log(color);
  };

  // useEffect(() => {
  //     fetch(url)
  //         .then(res => res.json())
  //         .then(data => setCatWisePdt(data.data))
  // }, [url])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navigation></Navigation>
      <div className="container-fluid py-3 bg-white">
        <div className="container">
          <nav aria-label="breadcrumb ">
            <ol className="breadcrumb products-breadcrumb m-0">
              <li className="breadcrumb-item">
                <a href="/#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/#">Product</a>
              </li>
              <li
                className="breadcrumb-item                                                            "
                aria-current="page"
              >
                T-Shirts
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="discount-msg">
          <h2 className="text-uppercase">Member Exclusive</h2>
          <p>15% OFF EVERYTHING + EXTRA $10 OFF FOR PLUS STATUS</p>
          <small>Not a member? Join now to shop.</small>
        </div>
      </div>

      <div className="container pb-5 bg-white">
        <div className="row ">
          {/* left side start */}
          <div className="col-12 col-sm-12 col-md-3 col-lg-3">
            <div className="new-arrival py-3">
              <h4 className="text-uppercase">NEW ARRIVALS</h4>
              <Link to="/#">New arrivals</Link>
            </div>

            <div className="shop-concept py-3">
              <h4 className="text-uppercase">SHOP BY CONCEPT</h4>
              <Link to="/#">Conscious</Link>
              <Link to="/#">Premium quality</Link>
              <Link to="/#">Classic collection</Link>
            </div>

            <div className="gender py-3">
              <h4 className="text-uppercase">GENDER</h4>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Man
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Woman
                </label>
              </div>
            </div>

            <div className="price py-3">
              <h4>PRICE</h4>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  0$ – 40$
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  40$ – 100$
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  100$ – 150$
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  150$ – 175$
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  175$ – 250$
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  250$ – 350$
                </label>
              </div>
            </div>
            {/* color section */}
            <div className="shop-concept py-3">
              <h4 className="text-uppercase">COLOR</h4>
              <div className="color-container ">
                {colorAll?.map((colorCode) => (
                  <div
                    className="color-div"
                    key={colorCode}
                    onClick={() => bappi(colorCode)}
                    style={{ background: colorCode }}
                  ></div>
                ))}
              </div>
            </div>
          </div>

          {/* right side start */}
          <div className="col-12 col-sm-12 col-md-9 col-lg-9">
            <div>
              <h2 className="product-title text-uppercase">BEDROOM</h2>
              <p>
                It's easy to transform your Bedroom interior with our great
                selection of accessories.
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="">
                <FiFilter className="me-2"></FiFilter>FILTER & SORT
              </p>
              <div className="d-flex ">
                <p className="me-4 text-danger">Models</p>
                <p>Products</p>
              </div>
            </div>
            {/* product container */}
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-4 py-3">
              {catWisePdt?.length > 0 ? (
                catWisePdt?.map((pdt) => (
                  <CatProductCard pdt={pdt} key={pdt.id}></CatProductCard>
                ))
              ) : (
                <p className="m-auto py-5">'No poduct avaiable'</p>
              )}
            </div>

            <button className="load-btn mx-auto  d-flex ">
              LOAD MORE PRODUCTS
            </button>
          </div>
        </div>
      </div>
      <Footers></Footers>
    </>
  );
};

export default SearchShow;
