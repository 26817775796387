import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./CompanyCreate.css";
import { useState } from "react";
import { baseurl } from "../../../baseUrl";

const CompanyCreate = () => {
  const [image, setImage] = useState("");
  const handleChange = (file) => {
    setImage(file[0]);
  };

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    // console.log(data)

    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("website", data?.website);
    formData.append("address", data?.address);
    formData.append("phone", data?.phone);
    formData.append(
      "delivery_charge_inside_dhaka",
      data?.delivery_charge_inside_dhaka
    );
    formData.append(
      "delivery_charge_outside_dhaka",
      data?.delivery_charge_outside_dhaka
    );
    formData.append("packaging_charge", data?.packaging_charge);
    formData.append("vat_registration_no", data?.packaging_charge);
    formData.append("trade_licence_no", data?.trade_licence_no);
    formData.append("image", data?.image[0]);

    fetch(`${baseurl}/dashboard/company/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => alert(data?.msg))
      .catch((error) => console.error("Error -> ", error));
    reset();
  };

  // axios.post('http://192.168.1.101:5001/dashboard/company/', data)
  //     .then(res => {
  //         if (res.data.insertId) {
  //             alert('added succesfully')
  //             reset();
  //         }
  //     })

  return (
    <div className="add-service py-3 ">
      <form
        className="input-type border shadow  mx-auto p-5 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <input {...register("name")} placeholder="Name" />
        <input {...register("website")} placeholder="Website" />
        <input {...register("address")} placeholder="Address" />
        <input type="text" {...register("phone")} placeholder="phone" />

        <input
          type="text"
          {...register("delivery_charge_inside_dhaka")}
          placeholder="Delivery_charge_inside_dhaka"
        />
        <input
          type="text"
          {...register("delivery_charge_outside_dhaka")}
          placeholder="Delivery_charge_outside_dhaka"
        />
        <input
          type="text"
          {...register("packaging_charge")}
          placeholder="Packaging_charge"
        />
        <input
          type="text"
          {...register("vat_registration_no")}
          placeholder="Vat_registration_no"
        />
        <input
          type="text"
          {...register("trade_licence_no")}
          placeholder="Trade_licence_no"
        />

        <input accept="image" type="file" {...register("image", {})} />
        <input id="submits-button" type="submit" />
      </form>
    </div>
  );
};

export default CompanyCreate;
