import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { baseurl } from "../baseUrl";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [items, setItems] = useState({});
  // const [orderItems, setOrderItems] = useState([]);
  // const [finalOrderData, setFinalOrderData] = useState([]);
  // const [prices, setPrices] = useState({});
  // const [orderedList, setOrderedList] = useState([]);
  // const [finalPrice, setFinalPrice] = useState();
  // const [customerData, setCustomerData] = useState({});

  const fetchItems = async () => {
    try {
      const res = await axios.get(`${baseurl}/dashboard/cart/list/`);
      setItems(res?.data?.data);
      // setOrderedList(res?.data?.data?.product_order?.ordered_items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch initial data when component mounts
    fetchItems();
  }, []);

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        fetchItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
