import React from "react";
import "./MensCollection.css";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";

const MensCollection = () => {
  const { description } = useFooterDetails();
  // console.log(description);
  const data = description?.mens_collection;

  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">Men's Collection</h1>
      <hr className="hr" />
      <div
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default MensCollection;
