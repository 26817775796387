import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdUpdate } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { GrView } from "react-icons/gr";
import useDashboard from "../../../Hooks/useDashboard";
import { RotatingLines, Triangle } from "react-loader-spinner";
import Swal from "sweetalert2";
import { baseurl } from "../../../baseUrl";

const Discount = () => {
  const [discount, setDiscount] = useState([]);
  // console.log(discount)
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //is loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/discount/`)
      .then((res) => res.json())
      .then((data) => setDiscount(data.data))
      .finally(() => setIsLoading(false));
  }, [setDiscount]);

  const handleDeleteOrder = (id) => {
    const state = confirm("Are you sure ?"); //eslint-disable-line
    if (state) {
      const url = `${baseurl}/dashboard/discount/${id}/`;
      fetch(url, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            Toast.fire({
              icon: "success",
              title: "Discount Deleted!",
            });
            const remainingUsers = discount.filter(
              (services) => services.id !== id
            );
            setDiscount(remainingUsers);
          }
        });
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <Link to="/dashboard/discountCreate">
            {" "}
            <button type="button" className=" create-button">
              + Discount Create
            </button>
            <br />
            <br />
          </Link>

          <Table className="table   table-bordered border-secondary rounded  shadow-sm  table-hover">
            <thead className="table-header">
              <tr>
                <th className="py-3" scope="col">
                  Discount title
                </th>
                <th className="py-3" scope="col">
                  Discount Image{" "}
                </th>
                <th className="py-3" scope="col">
                  Discription
                </th>
                <th className="py-3" scope="col">
                  Amount
                </th>

                <th className="py-3" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            {discount?.map((discounts, index) => (
              <tbody key={discounts.id}>
                <tr>
                  <td>{discounts?.title}</td>
                  <td>
                    <img
                      className="img-fluid"
                      src={discounts?.image_url}
                      alt=""
                      width={"100"}
                    />
                  </td>
                  <td>{discounts?.description}</td>
                  <td>{discounts?.amount}</td>

                  <td className="d-flex align-items-center">
                    <Link to={`/dashboard/discountDetails/${discounts.id}`}>
                      <GrView className="text-success fs-5"></GrView>
                    </Link>
                    <Link to={`/dashboard/discountUpdate/${discounts.id}`}>
                      <MdUpdate className="mx-2 fs-4"></MdUpdate>
                    </Link>
                    <TiDelete
                      className=" fs-3 text-danger"
                      role={"button"}
                      onClick={() => handleDeleteOrder(discounts?.id)}
                    ></TiDelete>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Discount;
