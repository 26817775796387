import React, { useEffect, useState } from "react";
import axios from "axios";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { RiHeartAddFill } from "react-icons/ri";
import "./Service.css";

// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules

import Swal from "sweetalert2";
import useCart from "../../Hooks/useCart";
import { Link } from "react-router-dom";
import ModalPopup from "../TailorMeNowDetails/ModalPopup/ModalPopup";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
// import { useId } from "react";
import { baseurl } from "../../baseUrl";

const Service = ({ product = {} }) => {
  const [selectedPdt, setSelectedPdt] = useState(product?.image_url);
  const [deliveryInfo, setDeliveryInfo] = useState([]);

  // order quantity
  const [count, setCount] = useState(1);
  const [cartData, setCartData] = useState({
    product_size: "",
    steel_color: "",
    board_color: "",
    quantity: 1,
  });

  // cart context api
  const cartInfo = useCart();
  const { fetchItems } = cartInfo;

  //error state
  // const [error, setError] = useState('');

  // sweetalert toast
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  //wishlist handler
  const wishlistHandler = () => {
    const baseUrl = `${baseurl}/dashboard/my_wish_list_create/`;
    axios
      .post(baseUrl, { product: product.id })
      .then(function (response) {
        if (response?.data?.status) {
          localStorage.setItem(
            "slug",
            JSON.stringify(response?.data?.data?.slug)
          );
          Toast.fire({
            icon: "success",
            title: "Product Added in Wishlist!!",
          });
        } else {
          alert(response?.data?.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // add to cart api
  const addToCartPdt = () => {
    const baseUrl = `${baseurl}/dashboard/cart/add/`;
    axios
      .post(baseUrl, cartData)
      .then(function (response) {
        if (response?.data?.data === null) {
          Swal.fire({
            html: response?.data?.msg,
            confirmButtonColor: "#d4b16c",
          });
        } else {
          Toast.fire({
            icon: "success",
            title: "Product Added!!",
          });
          fetchItems();
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  // delivery api calling
  const getDeliveryData = async () => {
    try {
      const res = await axios.get(`${baseurl}/dashboard/area_list/`);
      setDeliveryInfo(res?.data?.data);
      // setOrderedList(res?.data?.data?.product_order?.ordered_items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch initial data when component mounts
    getDeliveryData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="container py-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb products-breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to="/home" className="text-decoration-none text-secondary">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/products/${product?.category_details?.slug}`}
                className="text-decoration-none text-primary text-secondary"
              >
                {product?.category_details?.title}
              </Link>
            </li>
            {/* <li className="breadcrumb-item"><Link to="/all-products" className='text-decoration-none text-primary text-secondary'>Products</Link></li> */}
            <li className="breadcrumb-item active" aria-current="page">
              {product?.title}
            </li>
          </ol>
        </nav>
      </div>
      <div className="container mx-auto">
        <div className="row justify-content-center ">
          <div className="col-12 col-sm-12 col-md-4 product-details-img text-center">
            <div className="product-details-img">
              <InnerImageZoom src={selectedPdt} zoomSrc={selectedPdt} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <h1 className="card-title fs-4">{product?.title}</h1>
            {product?.dimension && (
              <p className="discount-price-txt fs-6">
                Dimension: {product?.dimension}
              </p>
            )}

            <div className="d-flex align-items-center">
              {!product?.discount_price && (
                <h5 className="product-price-card m-0 pe-3">
                  BDT {product?.price}
                </h5>
              )}
              {product?.discount_price && (
                <h5 className="product-price-card m-0 pe-3">
                  BDT {product?.discount_price}
                </h5>
              )}
              {product?.discount_price && (
                <p className=" text-decoration-line-through discount-price-txt m-0">
                  BDT {product?.price}
                </p>
              )}
            </div>

            {/* product color option  */}
            {product?.product_option?.length > 0 && (
              <div className="py-3">
                <p className="select-size-title me-3">Product Size:</p>

                <div className="d-flex select-size-btn-container">
                  {
                    <ButtonGroup>
                      {product?.product_option?.map((pdt_size, index) => (
                        <ToggleButton
                          key={pdt_size?.id}
                          id={`product-color-option-${pdt_size?.id}`}
                          type="radio"
                          variant={"outline-secondary"}
                          name="radio_product_option"
                          value={pdt_size?.id}
                          checked={pdt_size?.id === cartData?.product_size}
                          onChange={(e) => {
                            setCartData({
                              ...cartData,
                              product_size: pdt_size?.id,
                            });
                            if (pdt_size?.image_url) {
                              setSelectedPdt(pdt_size?.image_url);
                            } else {
                              setSelectedPdt(product?.image_url);
                            }
                          }}
                        >
                          {pdt_size?.size_title}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  }
                </div>
              </div>
            )}

            {/* steel color option  */}
            {product?.steel_color?.length > 0 && (
              <div className="py-3">
                <p className="select-size-title me-3">Steel Color:</p>

                <div className="d-flex select-size-btn-container">
                  {
                    <ButtonGroup>
                      {product?.steel_color?.map((steel_c, index) => (
                        <ToggleButton
                          key={steel_c?.id}
                          id={`steal-color-option-${steel_c?.id}`}
                          type="radio"
                          variant={"outline-secondary"}
                          name="radio_steel_color"
                          value={steel_c?.id}
                          checked={steel_c?.id === cartData?.steel_color}
                          onChange={(e) => {
                            // setSteelColor({
                            //   ...steelColor,
                            //   steel_color: steel_c?.id,
                            //   steel_slug: steel_c?.slug,
                            // });

                            setCartData({
                              ...cartData,
                              steel_color: steel_c?.id,
                            });
                          }}
                        >
                          {steel_c?.title}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  }
                </div>
              </div>
            )}

            {/* board color option  */}
            {product?.board_color?.length > 0 && (
              <div className="py-3">
                <p className="select-size-title me-3">Board Color:</p>
                <div className="d-flex select-size-btn-container">
                  <ButtonGroup>
                    {product?.board_color?.map((board_c, idx) => (
                      <ToggleButton
                        key={board_c?.id || idx}
                        id={`board-color-option-${board_c?.id}`}
                        type="radio"
                        variant={"outline-secondary"}
                        name="radio_board_color"
                        value={board_c?.id}
                        checked={board_c?.id === cartData?.board_color}
                        onChange={(e) => {
                          // setBoardColor(board_c?.title);
                          setCartData({
                            ...cartData,
                            board_color: board_c?.id,
                          });
                          if (board_c?.image_url) {
                            setSelectedPdt(board_c?.image_url);
                          } else {
                            setSelectedPdt(product?.image_url);
                          }
                        }}
                      >
                        {board_c?.title}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            )}

            <div className="pb-5">
              <p className="select-size-title me-3">Quantity:</p>
              <input
                type="button"
                value="-"
                className="incre-decre-btn"
                disabled={count === 1}
                style={{
                  background:
                    count === 1
                      ? "#333333"
                      : count === 2
                      ? "#d4b16c"
                      : "#d4b16c",
                }}
                onClick={() => {
                  setCount(count - 1);
                  setCartData({ ...cartData, quantity: count - 1 });
                }}
              />

              <input
                type="button"
                value={count}
                className=" text-center border-0 bg-light fw-bolder"
              />

              <input
                type="button"
                value="+"
                className="incre-decre-btn"
                onClick={() => {
                  setCount(count + 1);
                  setCartData({ ...cartData, quantity: count + 1 });
                }}
              />
            </div>

            <div className="">
              <button
                className="add-To-Cart-Btn-Card me-2"
                onClick={addToCartPdt}
              >
                <i className="fas fa-cart-plus me-2"></i>Add to cart
              </button>
              {product?.is_tailor_me && (
                <Link to={`/appointment/${product?.id}`}>
                  <button className="add-To-Cart-Btn-Card me-2">
                    Tailor Me
                  </button>
                </Link>
              )}
              {product?.is_tailor_me_now && (
                <ModalPopup slug={product?.slug}></ModalPopup>
              )}
              <button
                type="button"
                className="  wishlist-btn-icon "
                onClick={() => wishlistHandler()}
              >
                <RiHeartAddFill className="wishlist-d-icon "></RiHeartAddFill>
              </button>
            </div>
          </div>

          {/* delivery information */}
          {!!deliveryInfo?.length && (
            <div className="col-12 col-sm-12 col-md-4 delivery-info">
              <h6 className="delivery-title">Delivery</h6>
              <div>
                {deliveryInfo?.map((item, index) => (
                  <p key={item?.id ?? index}>
                    {item?.title} :<span>BDT {item?.delivery_charge}</span>
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
