import React from "react";
import "./Cart.css";
import useCart from "../../Hooks/useCart";
import CartProduct from "./CartProduct/CartProduct";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseurl } from "../../baseUrl";

const Cart = () => {
  let navigate = useNavigate();

  // cart context api
  const cartInfo = useCart();

  const { items, fetchItems } = cartInfo;
  //  remove order item from cart
  const order_items_filter_fn = (order_pdt_id) => {
    const deleteConfermation = window.confirm(
      "Do you want to delete this product?"
    );
    if (deleteConfermation) {
      axios
        .post(`${baseurl}/dashboard/cart/delete/?id=${order_pdt_id}`)
        .then(function (response) {
          if (response.status === 200) {
            // const remainingItems =
            //   orderedList?.product_order?.ordered_items?.filter(
            //     (pdt) => order_pdt_id !== pdt?.product_order?.id
            //   );
            fetchItems();
            // alert("Do you want to delete this product?");
          }
        })
        .catch(function (error) {
          alert("Delete unsuccessfull!");
          console.log(error);
        });
    }
  };

  // sweetalert toast for order
  // const Toast = Swal.mixin({
  //   toast: true,
  //   position: "bottom-end",
  //   background: "#000",
  //   color: "#FFF",
  //   showConfirmButton: false,
  //   timer: 3000,
  //   timerProgressBar: true,
  //   didOpen: (toast) => {
  //     toast.addEventListener("mouseenter", Swal.stopTimer);
  //     toast.addEventListener("mouseleave", Swal.resumeTimer);
  //   },
  // });

  const updateOrder = () => {
    navigate("/orderProcess");
  };

  return (
    <>
      {items?.product_order?.ordered_items?.length === 0 ||
      items?.product_order?.ordered_items === undefined ? (
        <p className="cart-empty-msg">Your Cart is Empty!!!</p>
      ) : (
        <div className="py-2">
          <div className="cart-pdt-total">
            {items?.product_order?.ordered_items?.map((orderItem, index) => (
              <div className="d-flex flex-column" key={`cart_${index}`}>
                <CartProduct
                  orderItem={orderItem}
                  order_items_filter_fn={order_items_filter_fn}
                ></CartProduct>
              </div>
            ))}
          </div>

          <div className="">
            <button
              className="checkout-btn w-100"
              data-dismiss="modal"
              data-bs-dismiss="modal"
              onClick={updateOrder}
            >
              Checkout
            </button>
            {/* <Link to="/orderProcess">
                                <button type="button" className="checkout-btn w-100" data-dismiss="modal" >Checkout</button>
                            </Link> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
