import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import CatagoryCard from "../CatagoryCard/CatagoryCard";
import "./ProductCatagories.css";
import { baseurl } from "../../../baseUrl";

const ProductCatagories = () => {
  const [productCat, setProductCat] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const products_filter_fn = (product_title) => {
    const product = productCat.filter((pdt) => product_title === pdt.title);
    setFilterProduct(product);
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/category_wise_product/`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setProductCat(data?.data);
        setFilterProduct(data?.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <h2 className="text-uppercase text-center product-cat-title ">
          We Design and Customize your dream furnitures
        </h2>
        {/* <div className='text-center py-5'>
                    {
                        loadingHandle()
                    }
                    <button
                        type="button"
                        className="fillter-btn m-3"
                        onClick={() => setFilterProduct(productCat)}
                    >ALL</button>
                    {
                        productCat?.map(p_cat_name => <button
                            type="button"
                            className="fillter-btn m-3"
                            key={p_cat_name.slug}
                            onClick={() => products_filter_fn(p_cat_name.title)}
                        >{p_cat_name.title}</button>)
                    }
                </div> */}
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 g-4 py-5">
          {filterProduct?.slice(0, 6).map((cat, index) => (
            <CatagoryCard
              // {...(index % 2 === 0 && { className: 'mt-lg-4' })}
              // cn={index % 2 === 0 ? 'mt-4' : ""}
              key={cat.slug}
              cat={cat}
            ></CatagoryCard>
          ))}
        </div>

        <Link to="/allProductCatagory" className="text-decoration-none">
          <button className="see-more-p-cat-btn mx-auto  d-flex ">
            View More
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCatagories;
