import React from 'react';
import { Spinner } from 'react-bootstrap';
import { RotatingLines, Triangle } from 'react-loader-spinner';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';

const PrivateRoute = ({ children, ...rest }) => {
    // auth context 
    const authInfo = useAuth();
    const { user, setUser, isLoading, setIsLoading } = authInfo;
    // console.log(user);

    let location = useLocation();

    if (isLoading) {
        return <div className="d-flex justify-content-center py-5">
            <RotatingLines
                strokeColor="#d4b16c"
                strokeWidth="3"
                animationDuration="0.75"
                width="50"
                visible={true}
            />
        </div>
    }
    if (user?.user?.email) {
        return children;
    } else {
        alert("Please login!!")

    }

    return <Navigate to="/login" state={{ from: location }} />;

};

export default PrivateRoute;