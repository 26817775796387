import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import SteelProductCard from "../../components/SteetProductCard/SteetProductCard";
import { baseurl } from "../../baseUrl";

const Steels = () => {
  const [tailorMe, setTailorMe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/steel_product_list/`)
      .then((res) => res.json())
      // .then((data) => console.log(data));
      .then((data) => setTailorMe(data.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="container py-3 mx-auto">
      <h2 className="monthly ">STEELS</h2>
      <hr className="horizontal-related " style={{ height: "3px" }} />
      <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-3 py-3">
        {tailorMe?.map((product) => (
          <SteelProductCard
            product={product}
            key={product.id}
          ></SteelProductCard>
        ))}
      </div>
    </div>
  );
};

export default Steels;
