import React from 'react';
import logo from '../../../../images/home-delivery.jpg';
import './WhyRatioCard.css';

const WhyRatioCard = ({ d }) => {

    return (
        <div className="col">
            <div className="card why-ratio-card">
                <img src={d.img} className="img-fluid mx-auto" alt="..." width={"60"} />
                <div className="card-body text-center">
                    <h5 className="card-title ">{d.title}</h5>
                    <p className="card-text">{d.sub_title}</p>
                </div>
            </div>
        </div>
    );
};

export default WhyRatioCard;