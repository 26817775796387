import React, { useState } from "react";
import "./OrderProcess.css";
import Checkout from "./Checkout/Checkout";
import Payment from "./Payment/Payment";
import Confirmation from "./Confirmation/Confirmation";
import Swal from "sweetalert2";
import axios from "axios";
import useCart from "../../Hooks/useCart";
import { baseurl } from "../../baseUrl";
// import withReactContent from 'sweetalert2-react-content'

const OrderProcess = () => {
  // cart context api
  const cartInfo = useCart();
  const { items, setItems } = cartInfo;
  // console.log(customerData)

  const [page, setPage] = useState(0);
  const [terms, setTerms] = useState(false);
  const FormTitles = ["Chekout", "Payment", "Confirmation"];

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const confirmOrderHandler = () => {
    axios
      .post(`${baseurl}/dashboard/confirm_order/`, {
        order_id: items?.product_order?.id,
      })
      .then(function (response) {
        if (response?.data?.status && terms) {
          setItems([]);
          Toast.fire({
            icon: "success",
            title: "Order Successful",
          });
          window.location.replace(
            response.data.data.payment_res.GatewayPageURL
          );
        } else {
          Swal.fire({
            html: !terms ? "Accept Terms & Condition" : response?.data?.msg,
            confirmButtonColor: "#d4b16c",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //console.log(formData);
  // const MySwal = withReactContent(Swal)

  const PageDisplay = () => {
    if (page === 0) {
      return <Checkout setPage={setPage}></Checkout>;
    } else if (page === 1) {
      return <Payment></Payment>;
    } else if (page === 2) {
      return <Confirmation terms={terms} setTerms={setTerms}></Confirmation>;
    }
  };

  return (
    <>
      {/* <Navigation></Navigation> */}
      <div className="container-fluid py-3">
        <div className="form container mx-auto">
          <div className="progressbar mx-auto">
            <div
              style={{
                width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%",
              }}
              className="d-flex"
            ></div>
          </div>
          <div className="form-container">
            <div className="header">{/* <h1>{FormTitles[page]}</h1> */}</div>
            <div className="body">{PageDisplay()}</div>
            <div className=" text-end py-3">
              <button
                className="control-btn"
                disabled={page === 0}
                onClick={() => {
                  setPage((currPage) => currPage - 1);
                }}
              >
                Prev
              </button>
              <button
                className="control-btn"
                onClick={() => {
                  if (
                    page === FormTitles.length - 1 &&
                    !!items?.product_order?.customer_info
                  ) {
                    confirmOrderHandler();
                  } else if (!!items?.product_order?.customer_info) {
                    setPage((currPage) => currPage + 1);
                  } else {
                    Swal.fire({
                      html: "Add billing address first",
                      confirmButtonColor: "#d4b16c",
                    });
                  }
                }}
              >
                {page === FormTitles.length - 1 ? "Pay Now" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderProcess;
