import axios from "axios";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import useCart from "../../../Hooks/useCart";
import DetailsOrder from "../DetailsOrder/DetailsOrder";
import "./Checkout.css";
import { baseurl } from "../../../baseUrl";
import { useForm } from "react-hook-form";

const Checkout = ({ setPage }) => {
  const [areaList, setAreaList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [customerData, setCustomerData] = useState({});

  const { handleSubmit, register } = useForm();

  // sweetalert toast for order
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // cart context api
  const { items, fetchItems } = useCart();
  const customerInfoHandler = (payload) => {
    axios
      .post(`${baseurl}/account/customer_info/`, payload)
      .then(function (response) {
        if (response.data.status) {
          fetchItems();
          setPage((currPage) => currPage + 1);
          Toast.fire({
            icon: "success",
            title: "Customer Info Updated!",
          });
        } else {
          alert(response?.data?.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/area_list/`)
      .then((response) => response.json())
      .then((data) => setAreaList(data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  const handleBillingAddress = (data) => {
    customerInfoHandler(data);
  };

  return (
    <div className="container-fluid">
      <div className="container mx-auto ">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 pb-3">
            <DetailsOrder></DetailsOrder>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 bg-white">
            <h3 className="py-2 order-process-all-title-txt">
              Billing Address
            </h3>
            <form
              className="row g-3"
              onSubmit={handleSubmit(handleBillingAddress)}
            >
              <div className="col-md-12">
                <label
                  htmlFor="name"
                  className="form-label order-process-sub-title-txt"
                >
                  Fullname
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Your Full Name"
                  required
                  defaultValue={items?.product_order?.customer_info?.name ?? ""}
                  {...register("name")}
                />
              </div>

              <div className="col-md-12">
                <label
                  htmlFor="email"
                  className="form-label order-process-sub-title-txt"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  required
                  className="form-control"
                  id="email"
                  placeholder="Email id"
                  defaultValue={
                    items?.product_order?.customer_info?.email_address ?? ""
                  }
                  {...register("email_address")}
                />
              </div>

              <div className="col-md-12">
                <label
                  htmlFor="phoneNumber"
                  className="form-label order-process-sub-title-txt"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Phone number"
                  onWheel={(e) => e.target.blur()}
                  defaultValue={
                    items?.product_order?.customer_info?.phone_number ?? ""
                  }
                  {...register("phone_number")}
                />
              </div>

              <div className="col-12">
                <label
                  htmlFor="shippingAddress"
                  className="form-label order-process-sub-title-txt"
                >
                  Shipping Address
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  id="shippingAddress"
                  placeholder="Ex: Sector-01 , plot-122 , Mohammadpur "
                  defaultValue={
                    items?.product_order?.customer_info?.shipping_address ?? ""
                  }
                  {...register("shipping_address")}
                />
              </div>

              <div className="col-md-12">
                <label
                  htmlFor="country"
                  className="form-label order-process-sub-title-txt"
                >
                  Country
                </label>
                <select
                  id="country"
                  required
                  className="form-select"
                  defaultValue={
                    items?.product_order?.customer_info?.country ?? ""
                  }
                  {...register("country")}
                >
                  <option value="">Choose one</option>
                  <option value="bangladesh">Bangladesh</option>
                </select>
              </div>

              <div className="col-md-7">
                <label
                  htmlFor="city"
                  className="form-label order-process-sub-title-txt"
                >
                  Area
                </label>
                <select
                  id="city"
                  required
                  className="form-select"
                  defaultValue={items?.product_order?.customer_info?.area ?? ""}
                  {...register("area")}
                >
                  <option value="">Choose one</option>
                  {areaList?.data?.map((areas) => (
                    <option value={areas?.title} key={areas?.id}>
                      {areas?.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-5">
                <label htmlFor="zipCode" className="form-label">
                  Zip
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="zipCode"
                  placeholder="Zip Code"
                  onWheel={(e) => e.target.blur()}
                  defaultValue={
                    items?.product_order?.customer_info?.zip_code ?? ""
                  }
                  {...register("zip_code")}
                />
              </div>

              {/* <div className="col-12 d-flex justify-content-between py-3 bg-light">
                                <button type="submit" className="cnt-shoppig-btn">Continue Shopping</button>
                                <button type="submit" className=" place-order-btn">Place My Order</button>
                            </div> */}
              <button type="submit" className="add-customer-info-btn">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
