import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductCard from "../../../components/ProductCard/ProductCard";

import "./AllProduct.css";
import { RotatingLines } from "react-loader-spinner";
import { baseurl } from "../../../baseUrl";

const AllProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sortingPdt, setSortingPdt] = useState([]);

  const sortingHandler = (sortingTitle) => {
    setSortingPdt([]);
    if (sortingTitle === "asc") {
      let low_high_pdt = sortingPdt.slice().sort((a, b) => a.price - b.price);
      return setSortingPdt(low_high_pdt);
    } else if (sortingTitle === "dsc") {
      let high_low_pdt = sortingPdt.slice().sort((a, b) => b.price - a.price);
      return setSortingPdt(high_low_pdt);
    } else if (sortingTitle === "all") {
      return setSortingPdt(sortingPdt);
    }
  };

  let url = `${baseurl}/dashboard/product/`;
  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSortingPdt(data?.data);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="container-fluid py-3 bg-white">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-8 col-sm-8 col-md-9 col-lg-10">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb products-breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/home" className="text-decoration-none text-dark">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/allProductCatagory"
                      className="text-decoration-none text-dark"
                    >
                      All Categories
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="col-4 col-sm-4 col-md-3 col-lg-2">
              <select
                className="sort-by-select "
                aria-label="Default select example"
                onChange={(e) => sortingHandler(e.target.value)}
              >
                <option selected value="all">
                  Sort by
                </option>
                <option value="asc">Price: Low to High</option>
                <option value="dsc">Price: High to Low</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container pb-5 bg-white">
          <div className="row ">
            {/* right side start */}
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              {/* product container */}
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-3">
                {sortingPdt?.length > 0 ? (
                  sortingPdt?.map((product) => (
                    <ProductCard
                      product={product}
                      key={product.id}
                    ></ProductCard>
                  ))
                ) : (
                  <div
                    className="alert alert-warning mx-auto text-center"
                    role="alert"
                  >
                    Products Not Available!!
                  </div>
                )}
              </div>

              {/* <button className='load-btn mx-auto  d-flex '>LOAD MORE PRODUCTS</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
