import React from 'react';
import "./Dashboard.css";
import { useState } from 'react';
import { MdApi } from "react-icons/md";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Container, Dropdown, DropdownButton, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import useAuth from '../../Hooks/useAuth';

const Dashboard = () => {
    const navigate = useNavigate();
    // auth context 
    const authInfo = useAuth();
    const { user } = authInfo;
    // console.log(user);

    const logout = () => {

        navigate("/home")
    }

    return (
        <div>
            <div className="d-flex" id="wrapper">


                <div className="bg-admin" id="sidebar-wrapper">
                    <div className="sidebar-heading text-center py-4 primary-text fs-5 fw-bold text-uppercase border-bottom">Dashboard</div>

                    <div className="list-group list-group-flush my-3">
                        <Link to="/home" className="list-group-item list-group-item-action bg-transparent second-text active"><MdApi fa-spin className=' fa-spin me-2' />Home</Link>

                        <Link to="/dashboard/company" className="list-group-item list-group-item-action bg-transparent second-text fw-bold text-white"><MdApi fa-spin="true" className=' fa-spin me-2'></MdApi>Company</Link>

                        <Link to="/dashboard/users" className="list-group-item list-group-item-action bg-transparent second-text fw-bold text-white"><MdApi fa-spin="true" className=' fa-spin me-2'></MdApi>Users</Link>

                        <Link to="/dashboard/promoCode" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className=' fa-spin me-2'></MdApi>Promocode</Link>

                        <Link to="/dashboard/discount" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Discount</Link>

                        <Link to="/dashboard/catagory" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Product Catagory</Link>

                        <Link to="/dashboard/product" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin className=' fa-spin me-2'></MdApi>Product</Link>

                        {/* <Link to="/dashboard/productColor" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin className=' fa-spin me-2'></MdApi>Product Color</Link>

                        <Link to="/dashboard/productSize" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin className=' fa-spin me-2'></MdApi>Product Size & Qnty</Link> */}


                        <Link to="/dashboard/order" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Order</Link>

                        <Link to="/dashboard/deliveredOrder" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Delivered Order</Link>

                        <Link to="/dashboard/review" className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold"><MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Review</Link>

                        <button className=" text-white list-group-item list-group-item-action bg-transparent second-text fw-bold" onClick={() => logout()}>
                            <MdApi fa-spin="true" className='fa-spin  me-2'></MdApi>Logout
                        </button>



                        {/* <DropdownButton
                            variant="btn-primary"
                            title={user?.user?.username}
                            id="dropdown-item-button"
                            className='text-white'
                        >
                            <Dropdown.Item href="#">{user?.user?.email}</Dropdown.Item>
                            <Dropdown.Item href="#">LogOut</Dropdown.Item>
                        </DropdownButton> */}

                    </div>
                </div>



                <div id="page-content-wrapper">

                    <div className='container-fluid'>
                        {/* <h1>Welcome Dzine BD Admin home</h1> */}
                        <Outlet></Outlet>
                    </div>
                </div>

            </div>

        </div>

    );
};

export default Dashboard;