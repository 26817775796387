import React from "react";
import { Container } from "react-bootstrap";
import useFooterDetails from "../../Hooks/useFooterDetails";
const WhatIsCustomizeUs = () => {
  const { description } = useFooterDetails();
  const data = description?.customize_us;
  return (
    <Container className="py-5">
      <h1 className="footer-link-pages">What is customize us?</h1>
      <hr className="hr" />
      <div
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Container>
  );
};

export default WhatIsCustomizeUs;
