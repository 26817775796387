import React, { useState } from "react";
import "./CustomizeUs.css";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import { useEffect } from "react";
import { baseurl } from "../../baseUrl";

const CustomizeUs = () => {
  const [sending, setSending] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const onSubmit = (data) => {
    // console.log(data)
    setSending(true);
    const formData = new FormData();
    formData.append("image_list", data.design_file[0]);
    // formData.append('recording_file', data.recording_file[0]);
    formData.append("name", data.name);
    formData.append("email_address", data.email_address);
    formData.append("phone_number", data.phone_number);
    formData.append("shipping_address", data.shipping_address);
    formData.append("country", data.country);
    formData.append("city", data.city);
    formData.append("zip_code", data.zip_code);

    axios
      .post(`${baseurl}/dashboard/customize_us_product_order/`, formData)
      .then(function (response) {
        // console.log(response);
        if (response.data.status) {
          Swal.fire(`${response.data.msg}`, "Welcome Ratio Family", "success");
          reset();
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setSending(false));
  };
  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/area_list/`)
      .then((response) => response.json())
      .then((data) => setAreaList(data))
      .finally(() => setIsLoading(false));
  }, []);

  if (sending) {
    return (
      <div className="d-flex justify-content-center align-items-center py-5 min-vh-100">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div className="container-fluid">
      <Container>
        <h2 className="title mt-4">Custom Order</h2>
        <hr className="horizontal-related " style={{ height: "3px" }} />
        {/* Form Start */}
        <form className="row mt-4" onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="choose-file p-3 mt-3">
          <small className="py-2">Upload your Voice recording here</small>
          <input
            type="file"
            className="file-form-control bg-transparent border-0 text-white"
            id=""
            {...register("recording_file", { required: true })}
          />
        </div> */}
          <div className="col-md-6 mt-3">
            <div className=" col-md-12 pb-3">
              <label htmlFor="name" className="form-label title-txt">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Type your name"
                {...register("name", { required: true })}
              />
            </div>

            <div className=" col-md-12 pb-3">
              <label htmlFor="email" className="form-label title-txt">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="example@gmail.com"
                {...register("email_address", { required: true })}
              />
            </div>

            <div className=" col-md-12 pb-3">
              <label htmlFor="number" className="form-label title-txt">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                id="number"
                placeholder="01700000000"
                {...register("phone_number", { required: true })}
              />
            </div>
            <div className=" col-md-12 pb-3">
              <label htmlFor="address" className="form-label title-txt">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Type your full address"
                {...register("shipping_address", { required: true })}
              />
            </div>
          </div>

          {/* 2nd column */}
          <div className="col-md-6 mt-3">
            <div className=" col-md-12 pb-3">
              <label htmlFor="country" className="form-label title-txt">
                Country
              </label>
              <select
                name="country"
                id="country"
                className="form-select"
                {...register("country", { required: true })}
              >
                <option selected>Choose...</option>
                <option value="bangladesh">Bangladesh</option>
              </select>
            </div>
            <div className=" col-md-12 pb-3 d-flex">
              <div className="col-md-6 me-2">
                <label htmlFor="city" className="form-label title-txt">
                  City
                </label>
                <select
                  name="city"
                  id="city"
                  className="form-select"
                  {...register("city", { required: true })}
                >
                  <option selected>Choose...</option>
                  {areaList?.data?.map((areas) => (
                    <option value={areas?.title} key={areas?.id}>
                      {areas?.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="zip-code" className="form-label title-txt">
                  Zip Code
                </label>
                <input
                  type="text"
                  id="zip-code"
                  className="form-control"
                  placeholder="Ex: 7400"
                  {...register("zip_code", { required: true })}
                />
              </div>
            </div>
            <div className="col-12  ">
              <label htmlFor="file-upload" className="form-label title-txt">
                Custom design file
              </label>

              <div className="choose-file py-1">
                <input
                  type="file"
                  required
                  className="file-form-control bg-transparent border-0 text-white"
                  id="file-upload"
                  {...register("design_file", { required: true })}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <input
              type="submit"
              className="appointment-submit-button my-5"
              value="Submit"
            />
          </div>
        </form>
      </Container>
    </div>
  );
};

export default CustomizeUs;
