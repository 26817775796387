import React from 'react';
import WhyRatioCard from './WhyRatioCard/WhyRatioCard';
import logo1 from '../../../images/home-delivery.jpg';
import logo2 from '../../../images/easy-payment.jpg';
import logo3 from '../../../images/warranty-v2.jpg';
import logo4 from '../../../images/assembling.jpg';
import logo5 from '../../../images/repair.jpg';
import logo6 from '../../../images/shifting-v2.jpg';

const WhyRatio = () => {

    const data = [
        {
            id: 1,
            title: "Easy Payment",
            sub_title: "Easy cash and card payment facility. Enjoy 0% interest for installment payment",
            img: logo2
        },
        {
            id: 2,
            title: "Home Delivery",
            sub_title: "We provide home delivery service all over Bangladesh",
            img: logo1
        },
        {
            id: 3,
            title: "Warranty Policy",
            sub_title: "1 Year warranty for all furniture",
            img: logo3
        },
        {
            id: 4,
            title: "Assembling",
            sub_title: "Our expert team is always ready to assemble your furniture for free!",
            img: logo4
        },
        {
            id: 5,
            title: "Repair",
            sub_title: "Free of cost repair for In-warranty products. However, if the warranty period has been expired the product can be repaired and charges will be applicable.",
            img: logo5
        }
        ,
        {
            id: 6,
            title: "Shifting",
            sub_title: "We provide you dismantling and re-assembling service. This service applies for Otobi products only and charges are applicable.",
            img: logo6
        }
    ]

    return (
        <div className='container-fluid'>
            <div className="container pt-5">

                <h2 className="monthly ">Why Ratio</h2>
                <hr className='horizontal-related ' style={{ height: "3px" }} />

                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 py-5'>
                    {data.map((d) => <WhyRatioCard
                        d={d}
                        key={d.id}
                    ></WhyRatioCard>)}

                </div>
            </div>
        </div>
    );
};

export default WhyRatio;