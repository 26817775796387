import React, { useEffect, useState } from "react";
import "./Navigation.css";
import { Container, Nav, Navbar } from "react-bootstrap";
// import logo from "../../../images/logo/dzinebd.png";
import { GiShoppingBag } from "react-icons/gi";
import { BsFillHeartFill } from "react-icons/bs";
import { NavHashLink } from "react-router-hash-link";
import Cart from "../../Cart/Cart";
import { Link } from "react-router-dom";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import useCart from "../../../Hooks/useCart";
// import useDashboard from "../../../Hooks/useDashboard";
import { baseurl } from "../../../baseUrl";

// Navigation part starting-------
const Navigation = () => {
  const [products, setProducts] = useState([]);
  // console.log(products)
  const [filteredData, setFilteredData] = useState([]);
  // console.log(filteredData)
  const [wordEntered, setWordEntered] = useState("");

  // cartProvider context data
  const { items } = useCart();

  const [company, setCompany] = useState({});

  useEffect(() => {
    fetch(`${baseurl}/dashboard/company_details/`)
      .then((res) => res.json())
      .then((data) => setCompany(data.data));
  }, []);

  useEffect(() => {
    fetch(`${baseurl}/dashboard/product/`)
      .then((res) => res.json())
      .then((data) => setProducts(data.data));
  }, []);

  // search filter handler
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    const newFilter = products.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="navigation-panel shadow-sm"
        fixed="top"
      >
        <Container>
          <Navbar.Brand as={NavHashLink} to="/">
            <img
              className="brand-img img-fluid mx-auto"
              src={company?.logo_url}
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto text-center">
              <Nav.Link
                as={NavHashLink}
                to="/home"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="1"
              >
                Home
              </Nav.Link>
              {/* <Nav.Link as={NavHashLink} to="/voucher" className="p-3 " style={{ fontSize: "1.2rem" }}>
                Voucher
              </Nav.Link> */}
              <Nav.Link
                as={NavHashLink}
                to="/allProductCatagory"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="2"
              >
                Categories
              </Nav.Link>
              <Nav.Link
                as={NavHashLink}
                to="/steels"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="3"
              >
                Steels
              </Nav.Link>
              <Nav.Link
                as={NavHashLink}
                to="/discountProducts"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="4"
              >
                Exclusive
              </Nav.Link>
              <Nav.Link
                as={NavHashLink}
                to="/all-products"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="5"
              >
                New Arrivals
              </Nav.Link>
              {/* <Nav.Link as={NavHashLink} to="/tailorMeAll" className="p-2 " style={{ fontSize: "1.1rem" }}>
                Tailor Me
              </Nav.Link>
              <Nav.Link as={NavHashLink} to="/tailorMeNow" className="p-2 " style={{ fontSize: "1.1rem" }}>
                Tailor Me Now
              </Nav.Link> */}
              <Nav.Link
                as={NavHashLink}
                to="/customizeUs"
                className="p-2 "
                style={{ fontSize: "1.1rem" }}
                eventKey="6"
              >
                Customize
              </Nav.Link>
              {/* <Nav.Link as={NavHashLink} to="/products" className='p-3 fs-5'>Products</Nav.Link> */}
            </Nav>
            <Nav className="text-center  align-items-center">
              <div className="searchInputs">
                <input
                  className="search-box"
                  type="text"
                  placeholder="Search entire store"
                  value={wordEntered}
                  onChange={handleFilter}
                  eventKey="7"
                />
                <div className="searchIcon">
                  {filteredData.length === 0 ? (
                    <AiOutlineSearch className="search-icon" />
                  ) : (
                    <AiOutlineClose
                      className="search-icon"
                      id="clearBtn"
                      onClick={clearInput}
                    />
                  )}
                </div>
              </div>

              {/* <Nav.Link as={NavHashLink} to="/dashboard" className='p-3 fs-5'>Dashboard</Nav.Link>
              <Nav.Link as={NavHashLink} to="/login" className='p-3 fs-5'>Log In</Nav.Link> */}
              <Nav.Link
                className=" fs-5 "
                as={Link}
                to="/my-wish-list"
                eventKey="8"
              >
                <button
                  type="button"
                  className="  wishlist-btn-icon rounded-circle"
                >
                  <BsFillHeartFill className="wishlist-icon"></BsFillHeartFill>
                  {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                    {orderItems?.length}
                  </span> */}
                </button>
              </Nav.Link>

              <Nav.Link className=" fs-5 " eventKey="9">
                <button
                  type="button"
                  className=" position-relative rounded-circle checkout-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <GiShoppingBag
                    className="cart-icon"
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                  ></GiShoppingBag>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                    {items?.product_order?.ordered_items?.length}
                  </span>
                </button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {filteredData.length !== 0 && (
        <div className="dataResult">
          {filteredData?.map((value, index) => {
            return (
              <Link
                key={index}
                className="dataItem"
                to={`/productDetails/${value.slug}`}
                rel="noreferrer"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <p>{value.title} </p>
                  <p>BDT: {value.price} </p>
                  <img src={value.image_url} alt="" width={"30"} />
                </div>
              </Link>
            );
          })}
        </div>
      )}

      {/* <!-- Button trigger modal --> */}
      {/* <button type="button" className="btn btn-danger  " data-bs-toggle="modal" data-bs-target="#exampleModal"> </button>l */}

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="">
                <Cart></Cart>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ht-item search" id="search">
        <input type="text" name="search" placeholder="Search" autocomplete="off" />
      </div> */}
    </>
  );
};

export default Navigation;
