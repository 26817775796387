import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import DetailsContent from "../DetailsContent/DetailsContent";
import RelatedProducts from "../RelatedProducts/RelatedProducts";
import Service from "./../Service/Service";
import { baseurl } from "../../baseUrl";

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState({});
  // console.log(product);
  // const [productColor, setProductColor] = useState({});
  // const [boardColor, setBoardColor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let url = `${baseurl}/dashboard/product_details/${productId}/`;

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setProduct(data?.data);
        // setBoardColor(data?.data?.board_color);
      })
      .finally(() => setIsLoading(false));
  }, [url]);

  console.log();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div>
      {/* <Navigation></Navigation> */}
      <Service
        key={product?.id}
        product={product}
        // productColor={productColor}
      ></Service>

      <DetailsContent product={product}></DetailsContent>

      {/* <ProductImage></ProductImage> */}

      <RelatedProducts key={product?.slug} product={product}></RelatedProducts>

      {/* <Footer></Footer> */}
      {/* <Footers></Footers> */}
    </div>
  );
};

export default ProductDetails;
