import React from "react";
import "./CartProduct.css";
// import cartImage from "../../../images/cart-img.png";
import { FaTrashAlt } from "react-icons/fa";
import useCart from "../../../Hooks/useCart";
import { baseurl } from "../../../baseUrl";
import axios from "axios";

const CartProduct = ({ orderItem, order_items_filter_fn }) => {
  const cartInfo = useCart();
  const { fetchItems } = cartInfo;

  const handleIncrement = () => {
    const newCount = orderItem?.quantity + 1;
    updateOrderHandler(orderItem?.id, newCount);
  };

  const handleDecrement = () => {
    if (orderItem?.quantity > 1) {
      const newCount = orderItem?.quantity - 1;
      updateOrderHandler(orderItem?.id, newCount);
    }
  };

  const updateOrderHandler = (id, quantity) => {
    const url = `${baseurl}/dashboard/update_order_item/`;
    const data = { id, quantity }; // Create an object with id and quantity properties

    axios
      .post(url, data)
      .then(function (response) {
        fetchItems();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="row align-items-center p-2">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center ">
          <img
            src={orderItem?.product_details?.product_image_url}
            alt=""
            className=" cart-img"
          />
          <div className="ms-2">
            <p className=" m-0 cards-title">
              {orderItem?.product_details?.product_name}
            </p>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-end align-items-center">
          <div className="d-flex  py-4 align-items-center justify-content-center gap-2">
            {/* decriment btn  */}
            <input
              type="button"
              value="-"
              className="incre-decre-btn"
              onClick={handleDecrement}
            />

            {/* product qnt  */}
            <input
              type="button"
              value={orderItem?.quantity}
              className=" text-center border-0 bg-light fw-bolder cart-product-total-price"
            />

            {/* increment btn  */}
            <input
              type="button"
              value="+"
              className="incre-decre-btn"
              onClick={handleIncrement}
            />

            <p className=" p-2 m-0 cart-product-total-price">
              {orderItem?.product_details?.product_total_price}
            </p>

            <div
              className="inline"
              onClick={() => order_items_filter_fn(orderItem?.id)}
            >
              <FaTrashAlt className=" delete-btn"></FaTrashAlt>
            </div>
          </div>
        </div>
      </div>
      <hr className="cart-hr" />
    </>
  );
};

export default CartProduct;
