import axios from "axios";
import React, { useState } from "react";
import { baseurl } from "../../../baseUrl";

const ProductSizeQnty = ({ colorId }) => {
  const sizeTamplate = {
    title: "",
    available_quantity: "",
    product_color: colorId,
  };
  const [productSize, setProductSize] = useState([sizeTamplate]);

  // console.log(productSize);

  const [title, setTitle] = useState("");

  const handleChangeInput = (e, idx) => {
    // productSize[index] = event.target.value;
    // console.log(event.target.value)
    const newPdtColor = productSize.map((color, index) => {
      if (index === idx) {
        color[e.target.name] = e.target.value;
      }
      return color;
    });
    setProductSize(newPdtColor);
  };

  //add new input field
  const addMoreInputHandle = () => {
    const newInput = [...productSize, sizeTamplate];
    return setProductSize(newInput);
  };

  //delete field
  const remainingField = (idx) => {
    const remainingInputField = productSize.filter(
      (pdtColor, index) => index !== idx
    );
    return setProductSize(remainingInputField);
  };

  const sizeAddHandler = () => {
    axios
      .post(`${baseurl}/dashboard/product_size/`, productSize)
      .then(function (response) {
        // console.log(response);
        if (response.data.status) {
          alert(response.data.msg);
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="container-fluid py-3 ">
      {/* <h2 className='py-3 fw-bolder text-secondary '>Add Product Size & Qnty</h2> */}
      <div className="  ">
        <div className="">
          <form>
            {productSize.map((pdtColor, index) => (
              <div
                key={index}
                className="row  g-3 align-items-center my-2 border p-3 bg-light mx-auto"
              >
                <div className="col-6 m-0 ">
                  <div className="form-floating">
                    <input
                      placeholder="Product Title"
                      className="form-control"
                      id="title"
                      name="title"
                      value={pdtColor.title}
                      onChange={(e) => handleChangeInput(e, index)}
                    />
                    <label htmlFor="Title">Size</label>
                  </div>
                </div>
                <div className="col-5 m-0">
                  <div className="form-floating">
                    <input
                      placeholder="Product Title"
                      className="form-control"
                      id="available_quantity"
                      name="available_quantity"
                      value={pdtColor.available_quantity}
                      onChange={(e) => handleChangeInput(e, index)}
                    />
                    <label htmlFor="available_quantity">Quantity</label>
                  </div>
                </div>
                <div className="col-1 m-0">
                  <button
                    type="button"
                    className="btn btn-danger me-1 fw-bolder"
                    disabled={productSize.length === 1}
                    onClick={() => {
                      remainingField(index);
                    }}
                  >
                    {" "}
                    -
                  </button>
                </div>
              </div>
            ))}
          </form>
          <button
            className="btn btn-success my-2"
            type="submit"
            onClick={addMoreInputHandle}
          >
            + Add More
          </button>

          <button
            className="btn btn-success my-2 mx-2"
            type="submit"
            onClick={sizeAddHandler}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductSizeQnty;
