import React from 'react';

const PaymentFailed = () => {

    return (
        <div>
            failed
        </div>
    );
};

export default PaymentFailed;