import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import ProductCard from "../../../components/ProductCard/ProductCard";
import ShowDeals from "../ShowDeals/ShowDeals";
import "./MonthlyDeals.css";
import { baseurl } from "../../../baseUrl";

// After style then MonthlyDeals start-------

const MonthlyDeals = () => {
  const [deals, setDeal] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(deals)
  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/top_sell_product/`)
      .then((res) => res.json())
      .then((data) => setDeal(data.data))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container py-3 mx-auto">
      <h2 className="monthly ">New Arrivals</h2>

      {/* <hr className="monthly-hr" /> */}
      <hr className="horizontal-related " style={{ height: "3px" }} />
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3 py-3">
        {deals?.map((product) => (
          <ProductCard product={product} key={product.id}></ProductCard>
        ))}
      </div>
    </div>
  );
};

export default MonthlyDeals;
