import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useDashboard from "../../../Hooks/useDashboard";
import { baseurl } from "../../../baseUrl";

const CompanyUpdate = () => {
  const { companyId } = useParams();
  const [company, setCompany] = useState({});
  // console.log(company);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const companyUpdateHandler = () => {
    const formData = new FormData();
    formData.append("name", company?.name);
    formData.append("address", company?.address);
    formData.append("office_hour", company?.office_hour);
    formData.append("website", company?.website);
    formData.append("facebook_url", company?.facebook_url);
    formData.append("instagram_url", company?.instagram_url);
    formData.append("linkedin_url", company?.linkedin_url);
    formData.append("twitter_url", company?.twitter_url);
    formData.append("phone", company?.phone);
    formData.append("service_phone", company?.service_phone);
    formData.append("customer_service_phone", company?.customer_service_phone);
    formData.append(
      "delivery_charge_inside_dhaka",
      company?.delivery_charge_inside_dhaka
    );
    formData.append(
      "delivery_charge_outside_dhaka",
      company?.delivery_charge_outside_dhaka
    );
    formData.append("packaging_charge", company?.packaging_charge);
    formData.append("vat_registration_no", company?.vat_registration_no);
    formData.append("trade_licence_no", company?.trade_licence_no);
    formData.append("logo", company?.logo[0]);

    axios
      .patch(`${baseurl}/dashboard/company/${companyId}/`, formData)
      .then(function (response) {
        if (response.data.status) {
          Toast.fire({
            icon: "success",
            title: "Company Info Updated!",
          });
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetch(`${baseurl}/dashboard/company/${companyId}/`)
      .then((res) => res.json())
      .then((data) => setCompany(data.data));
  }, [companyId]);

  return (
    <div className="container-fluid py-3 p-4 shadow ">
      <div className="container ">
        <h2 className="py-3 fw-bolder text-secondary card-title">
          Update Your Company
        </h2>

        <form className="row g-3">
          <div className="col-md-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="companyName"
                placeholder="Company Name"
                required
                value={company.name || " "}
                onChange={(e) => {
                  setCompany({ ...company, name: e.target.value });
                }}
              />
              <label htmlFor="companyName">Company Name</label>
            </div>
          </div>

          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="companyPhone"
                placeholder="Company Phone"
                value={company.phone || ""}
                onChange={(e) =>
                  setCompany({ ...company, phone: e.target.value })
                }
              />
              <label htmlFor="companyPhone">Company phone</label>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-floating ">
              <textarea
                type="text"
                className="form-control"
                id="companyAddress"
                placeholder="Company Address"
                value={company.address || ""}
                onChange={(e) =>
                  setCompany({ ...company, address: e.target.value })
                }
              />
              <label htmlFor="companyAddress">Company address</label>
            </div>
          </div>

          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="service_phone"
                placeholder="service Phone"
                value={company.service_phone || " "}
                onChange={(e) => {
                  setCompany({ ...company, service_phone: e.target.value });
                }}
              />
              <label htmlFor="service_phone">Service Phone</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="Website"
                placeholder="Website"
                value={company.website || " "}
                onChange={(e) => {
                  setCompany({ ...company, website: e.target.value });
                }}
              />
              <label htmlFor="Website">Website</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="customer_service_phone"
                placeholder="Customer service Phone"
                value={company.customer_service_phone || " "}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    customer_service_phone: e.target.value,
                  });
                }}
              />
              <label htmlFor="customer_service_phone">
                Customer Service Phone
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="facebook_url"
                placeholder="facebook"
                value={company.facebook_url || " "}
                onChange={(e) => {
                  setCompany({ ...company, facebook_url: e.target.value });
                }}
              />
              <label htmlFor="facebook_url">Facebook Url</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="instagram_url"
                placeholder="facebook"
                value={company.instagram_url || " "}
                onChange={(e) => {
                  setCompany({ ...company, instagram_url: e.target.value });
                }}
              />
              <label htmlFor="instagram_url">Instagram Url</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="linkedin_url"
                placeholder="Linkedin"
                value={company.linkedin_url || " "}
                onChange={(e) => {
                  setCompany({ ...company, linkedin_url: e.target.value });
                }}
              />
              <label htmlFor="linkedin_url">Linkedin Url</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="twitter_url"
                placeholder="Twitter"
                value={company.twitter_url || " "}
                onChange={(e) => {
                  setCompany({ ...company, twitter_url: e.target.value });
                }}
              />
              <label htmlFor="twitter_url">Twitter Url</label>
            </div>
          </div>

          <div className="col-6">
            <div className="form-floating">
              <input
                className="form-control"
                id="logo"
                accept="image"
                type="file"
                onChange={(e) => {
                  setCompany({ ...company, logo: e.target.files });
                }}
              />
              <label htmlFor="logo">Logo</label>
            </div>
          </div>
          {/* <div className="col-6">
                        <div className="form-floating ">
                            <input type="text" className="form-control" id="logo_url" placeholder="Logo"
                                value={company.logo_url || " "}
                                onChange={(e) => {
                                    setCompany({ ...company, logo_url: e.target.value })
                                }} />
                            <label htmlFor="logo_url">Logo Url</label>
                        </div>
                    </div> */}
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="office_hour"
                placeholder="Office Hour"
                value={company.office_hour || " "}
                onChange={(e) => {
                  setCompany({ ...company, office_hour: e.target.value });
                }}
              />
              <label htmlFor="office_hour">Office Hour</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="packaging_charge"
                placeholder="Packaging Charge"
                value={company.packaging_charge || " "}
                onChange={(e) => {
                  setCompany({ ...company, packaging_charge: e.target.value });
                }}
              />
              <label htmlFor="packaging_charge">Packaging Charge</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="trade_licence_no"
                placeholder="Trade licence No"
                value={company.trade_licence_no || " "}
                onChange={(e) => {
                  setCompany({ ...company, trade_licence_no: e.target.value });
                }}
              />
              <label htmlFor="trade_licence_no">Trade licence No</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="vat_registration_no"
                placeholder="Vat Registration No"
                value={company.vat_registration_no || " "}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    vat_registration_no: e.target.value,
                  });
                }}
              />
              <label htmlFor="vat_registration_no">Vat Registration No</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="delivery_charge_inside_dhaka"
                placeholder="Delivery Charge Inside Dhaka"
                value={company.delivery_charge_inside_dhaka || " "}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    delivery_charge_inside_dhaka: e.target.value,
                  });
                }}
              />
              <label htmlFor="delivery_charge_inside_dhaka">
                Delivery Charge Inside Dhaka
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="delivery_charge_outside_dhaka"
                placeholder="Delivery Charge Outside Dhaka"
                value={company.delivery_charge_outside_dhaka || " "}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    delivery_charge_outside_dhaka: e.target.value,
                  });
                }}
              />
              <label htmlFor="delivery_charge_outside_dhaka">
                Delivery Charge Outside Dhaka
              </label>
            </div>
          </div>

          <div className="col-12">
            <button
              type="button"
              className="update-btn"
              onClick={companyUpdateHandler}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyUpdate;
