import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";
import { baseurl } from "../../../baseUrl";

const Review = () => {
  const [review, setReview] = useState([]);

  useEffect(() => {
    fetch(`${baseurl}/dashboard/review/`)
      .then((res) => res.json())
      .then((data) => setReview(data.data));
  }, [setReview]);

  // const handleDeleteOrder = id => {

  //     const url = `http://192.168.1.101:5001/dashboard/category/${id}/`;
  //     fetch(url, {
  //         method: 'DELETE'
  //     })
  //         .then(res => res.json())
  //         .then(data => {
  //             if (data) {
  //                 alert('deleted successfully')
  //                 const remainingUsers = discount.filter(services => services.id !== id)
  //                 setDiscount(remainingUsers)

  //             }
  //         })
  // }

  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <Table className="table   table-bordered border-primary rounded  shadow-sm  table-hover">
            <thead className="table-header">
              <tr>
                <th className="py-3" scope="col">
                  Order No
                </th>
                <th className="py-3" scope="col">
                  Review text{" "}
                </th>
                <th className="py-3" scope="col">
                  Rating
                </th>
              </tr>
            </thead>
            {review?.map((reviews, index) => (
              <tbody>
                <tr>
                  <td>{reviews?.order}</td>

                  <td>{reviews?.review_text}</td>
                  <td>{reviews?.rating}</td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Review;
