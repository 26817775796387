import React, { useState, useEffect } from "react";
import { Button, Carousel, CarouselItem, Modal } from "react-bootstrap";
import { baseurl } from "../../baseUrl";

const OnLoadPopUp = () => {
  const [show, setShow] = useState(true);
  const [popUpSlider, setPopUpSlider] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetch(`${baseurl}/dashboard/popup_list/`)
      .then((res) => res.json())
      .then((data) => setPopUpSlider(data.data));
  }, []);
  return (
    <>
      <Modal
        show={popUpSlider ? show : false}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title>Dzine Bangladesh</Modal.Title>
                </Modal.Header> */}
        <Modal.Body className="p-0">
          <Carousel>
            {popUpSlider?.map((slider) => (
              <Carousel.Item key={slider.id}>
                <img
                  className="d-block w-100"
                  src={slider.image_url}
                  alt="First slide"
                />
                <Carousel.Caption>
                  {/* <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default OnLoadPopUp;
