import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import useCart from "../../../Hooks/useCart";
import { baseurl } from "../../../baseUrl";

const DetailsOrder = () => {
  const [promoTxt, setPromoTxt] = useState(true);

  // sweetalert toast
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // cart context api
  const { items, fetchItems } = useCart();

  const promoCodeHandler = () => {
    const url = `${baseurl}/dashboard/applied_promo_code/`;
    axios
      .post(url, {
        order_id: items?.product_order?.id,
        promo_code: promoTxt,
      })
      .then(function (response) {
        if (response?.data?.status) {
          // setItems(response?.data?.data);
          // setPrices(response?.data?.data?.price);
          fetchItems();
          setPromoTxt("");
          Toast.fire({
            icon: "success",
            title: "Promo Code Added!",
          });
        } else {
          alert(response?.data?.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="table-responsive">
      <table className="table bg-white">
        <thead>
          <tr>
            <td className="py-3 p-2 order-process-all-title-txt">
              Detail Order
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="order-process-sub-title-txt">Product Total Price</td>
            <td className="order-process-sub-title-txt">
              {items?.product_order?.grand_total}
            </td>
          </tr>
          {/* <tr>
                        <td className='fw-bolder order-process-sub-title-txt'>Grand Total</td>
                        <td className='fw-bolder order-process-sub-title-txt'>{prices?.grand_total_price} BDT</td>

                    </tr> */}
          <tr>
            <td className="order-process-sub-title-txt">Discounted Price</td>
            <td className="order-process-sub-title-txt">
              {items?.product_order?.discount_amount}
            </td>
          </tr>
          <tr>
            <td className="order-process-sub-title-txt">Shipping Cost </td>
            <td className="order-process-sub-title-txt">
              {items?.product_order?.price?.delivery_charge}
            </td>
          </tr>
          <tr>
            <td className="order-process-sub-title-txt">Packaging</td>
            <td className="order-process-sub-title-txt">
              {items?.product_order?.price?.packaging_price}
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td className="fw-bolder order-process-sub-title-txt">Payable</td>
            <td className="fw-bolder order-process-sub-title-txt">
              {items?.product_order?.payable_amount}
            </td>
          </tr>
        </tfoot>
      </table>

      {!Boolean(items?.product_order?.applied_promo_code) && (
        <>
          <input
            type="text"
            placeholder="Discount Code"
            className="promo-input p-2 "
            onChange={(e) => setPromoTxt(e.target.value)}
          />
          <button className="add-customer-info-btn" onClick={promoCodeHandler}>
            Apply
          </button>
        </>
      )}
    </div>
  );
};

export default DetailsOrder;
