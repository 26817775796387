import React from 'react';
import { Link } from 'react-router-dom';
import './ProductCard.css';

import { BsFillCartPlusFill } from 'react-icons/bs';

const ProductCard = ({ product }) => {
  const {
    title,
    image_url,
    price,
    offer,
    slug,
    discount_price,
    discount_title,
  } = product;
  // console.log(product);
  return (
    <div className="col">
      <Link to={`/productDetails/${slug}`} className="text-decoration-none">
        <div className="c-product-card h-100">
          <div className="pdt-card-img-container">
            {discount_title && (
              <button className="pdt-card-discount-title">
                {discount_title}
              </button>
            )}
            <img
              src={image_url}
              className="img-fluid cat-product-img "
              alt="..."
            />
          </div>

          <div className="card-body p-3">
            <h5 className="c-product-title">{title}</h5>
            <div className="d-flex justify-content-between">
              {discount_price ? (
                <p className="c-product-price">BDT {discount_price}</p>
              ) : (
                <p className="c-product-price">BDT {price}</p>
              )}
              {discount_price && (
                <p className="c-product-discount-price text-secondary text-decoration-line-through">
                  BDT {price}
                </p>
              )}
            </div>
          </div>

          {/* <div className='btn-holder'>
    <Link to={`/productDetails/${slug}`}>
        <button className='add-to-c-pdt '><i className="fas fa-cart-plus me-2" style={{ color: "#d4b16c" }} ></i>Add to Cart</button>
    </Link>

</div> */}
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
