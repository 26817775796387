import React from 'react';
import { Link } from 'react-router-dom';
import './CatProductCard.css';

const CatProductCard = ({ pdt }) => {
    const { title, image_url, price, slug, discount_title, discount_price } = pdt;
    return (
        <div className="col">
            <Link to={`/productDetails/${slug}`} className="text-decoration-none">
                <div className="c-product-card h-100">
                    {/* <div className='cat-product-img-bg'>
                    <img src={image_url} className="img-fluid cat-product-img p-4 " alt="..." />
                </div> */}
                    <div className="pdt-card-img-container">
                        {discount_title && <button className='pdt-card-discount-title'>{discount_title}</button>}
                        <img src={image_url} className="img-fluid cat-product-img " alt="..." />
                    </div>

                    <div className="card-body p-3">
                        <h5 className="c-product-title">{title}</h5>
                        <div className='d-flex justify-content-between'>
                            {discount_price ? <p className="c-product-price">BDT {discount_price}</p> : <p className="c-product-price">BDT {price}</p>}
                            {discount_price && <p className="c-product-discount-price text-secondary text-decoration-line-through">BDT {price}</p>}
                        </div>

                    </div>

                    {/* <div className='btn-holder'>
                        <Link to={`/productDetails/${slug}`}>
                            <button className='add-to-c-pdt '><i className="fas fa-cart-plus me-2" ></i>Add to cart</button>
                        </Link>

                    </div> */}
                </div>
            </Link>

        </div>
    );
};

export default CatProductCard;