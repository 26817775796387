import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { CircleLoader } from "react-spinners";

import { InfinitySpin, RotatingLines } from "react-loader-spinner";
import WishListProductCard from "./WishListProductCard/WishListProductCard";
import { baseurl } from "../../baseUrl";

const WishListProducts = () => {
  // const [slug, setSlug] = useState("");

  const [myWishList, setMyWishList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let [color, setColor] = useState("#d4b16c");

  // console.log(myWishList);

  // useEffect(() => {

  //     setIsLoading(true)
  //     fetch(`http://192.168.1.101:5001/dashboard/my_wish_list/${slug}/`)
  //         .then(res => res.json())
  //         .then(data => setMyWishList(data.data))
  //         .finally(() => setIsLoading(false))
  // }, [slug])

  useEffect(() => {
    const slug = JSON.parse(localStorage.getItem("slug"));
    if (slug) {
      fetch(`${baseurl}/dashboard/my_wish_list/${slug}/`)
        .then((res) => res.json())
        .then((data) => setMyWishList(data.data));
      // setSlug(slug);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container-fluid p-lg-0 p-md-0">
      <div className="discount-msg ">
        <h2 className="text-uppercase">Your favourite products !!!</h2>
      </div>
      <div className="container py-3">
        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 py-3">
          {myWishList?.length === 0 ? (
            <div
              className="alert alert-warning mx-auto my-5 text-center"
              role="alert"
            >
              Your Wishlist is currently empty!!!
            </div>
          ) : (
            myWishList?.map((wish_list_pdt, index) => (
              <WishListProductCard
                key={wish_list_pdt.id}
                wish_list_pdt={wish_list_pdt}
              ></WishListProductCard>
            ))
          )}
          {/* { } */}
        </div>
      </div>
    </div>
  );
};

export default WishListProducts;
