import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sllCommz from "../../../images/ssl-commerce.png";
import useFooterDetails from "../../../Hooks/useFooterDetails";
import "./FooterNew.css";
import { baseurl } from "../../../baseUrl";

const FooterNew = () => {
  const { description } = useFooterDetails();
  const data = description?.size_guid_url;
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    fetch(`${baseurl}/dashboard/company_details/`)
      .then((res) => res.json())
      .then((data) => setFooterInfo(data?.data));
  }, []);

  return (
    <div className="container-fluid footers">
      <div className="container mx-auto">
        <div className="row pt-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 text-white py-3 ">
            {/* <img
              className="brand-img img-fluid mb-3"
              src={footerInfo?.logo_url}
              alt=""
            /> */}
            <h5 className="footer-title">{description?.title}</h5>
            <hr className="footer-hr" />
            {/* <p className="footer-title-sub">{footerInfo?.address}</p>
            <br /> */}
            <p className="footer-title-description">
              {description?.description}
            </p>
            <div className="billing-and-shipping-policy-section">
              {/* <p>Billing and shipping policy</p> */}
              <p>{description?.biin_number}</p>
              <p>{description?.customer_phone}</p>
            </div>
            {/* social links */}
            <div className="py-3 d-flex align-items-center">
              <a
                className="social-anchor"
                href={description?.facebook_url}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="fab fa-facebook social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={description?.instagram_url}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="fab fa-instagram social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={description?.twitter_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter social-icon"></i>
              </a>
              <a
                className="social-anchor"
                href={description?.youtube_url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube social-icon"></i>
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-2 text-white py-3">
            <h2 className="footer-title">Services</h2>
            <hr className="footer-hr" />
            <div>
              {/* <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/sale">
                  Sale
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/customerService"
                >
                  Customer Service
                </Link>
              </h6> */}
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/privacyPolicy"
                >
                  Privacy Policy
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/terms_conditions"
                >
                  Terms & Conditions
                </Link>
              </h6>
              {/* <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/billingShippingPolicy"
                >
                  Billing & Shipping Policy
                </Link>
              </h6> */}
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/returnExchangePolicy"
                >
                  Return & Exchange Policy
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/trackOrder"
                >
                  Track your order
                </Link>
              </h6>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-2 text-white py-3">
            <h2 className="footer-title">Information</h2>
            <hr className="footer-hr" />
            <div>
              <h6 className="footer-title-sub ">
                <Link className="text-decoration-none text-dark" to="/aboutUs">
                  About Us
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/contactUs"
                >
                  Contact Us
                </Link>
              </h6>

              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/factoryLocation"
                >
                  Factory Location
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/storeLocation"
                >
                  Store Location
                </Link>
              </h6>
              {/* <h6 className="footer-title-sub">
                <Link
                  className="text-decoration-none text-dark"
                  to="/eliteMembership"
                >
                  Elite Membership
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/joinUs">
                  Join Us
                </Link>
              </h6> */}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-3 text-white py-3">
            <h2 className="footer-title">Contact</h2>
            <hr className="footer-hr" />
            <div>
              <h6 className="footer-title-sub ">
                <Link className="text-decoration-none text-dark" to="/">
                  01860951003
                </Link>
              </h6>
              <h6 className="footer-title-sub">
                <Link className="text-decoration-none text-dark" to="/">
                  Everyday(10.00 AM- 6.00 PM)
                </Link>
              </h6>
            </div>
            <img src={sllCommz} alt="" className="img-fluid" />
          </div>
        </div>

        <p className="copyright-text pb-3">
          Copyright © 2022.{" "}
          <a
            href="http://www.techsistltd.com/"
            target={"_blank"}
            rel="noreferrer"
            className="text-decoration-none text-dark"
          >
            Techsist Limited
          </a>{" "}
          . All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default FooterNew;
