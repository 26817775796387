import React, { useState } from "react";
import "./CustomerInformation.css";
import { Container } from "react-bootstrap";
import useTailorMeNowData from "../../Hooks/useTailorMeNowData";
import axios from "axios";
import Swal from "sweetalert2";
import { baseurl } from "../../baseUrl";

const CustomerInformation = () => {
  // context api for tailor now data passing
  const { userInfo, setUserInfo } = useTailorMeNowData();
  // console.log(userInfo);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  const tailor_me_now_customer_info = {
    name: name,
    email_address: email,
    phone_number: phone,
    shipping_address: address,
    city: city,
    country: country,
    zip_code: zipCode,
  };
  // console.log(tailor_me_now_customer_info);

  const submitValue = () => {
    axios
      .post(`${baseurl}/dashboard/tailor_me_now_product_order/`, userInfo)
      .then(function (response) {
        // console.log(response);
        if (response.data.status) {
          Swal.fire(`${response.data.msg}`, "Welcome Ratio Family", "success");
          // reset();
        } else {
          alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Container>
      <h2 className="title mt-3">Customer Information</h2>
      <hr className="hr" />
      <form className="row">
        <div className="col-md-6">
          <div className=" col-md-12 pb-3">
            <label htmlFor="name" className="form-label title-txt">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Ex: S M Aliuzzaman"
              value={userInfo?.name}
              onChange={(event) => {
                setName(event.target.value);
                setUserInfo({ ...userInfo, name: event.target.value });
              }}
            />
          </div>

          <div className=" col-md-12 pb-3">
            <label htmlFor="email" className="form-label title-txt">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Ex: aliuzzaman.uiu026@gmail.com"
              value={userInfo?.email_address}
              onChange={(event) => {
                setEmail(event.target.value);
                setUserInfo({ ...userInfo, email_address: event.target.value });
              }}
            />
          </div>

          <div className=" col-md-12 pb-3">
            <label htmlFor="number" className="form-label title-txt">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              id="number"
              placeholder="Ex: 01788651774"
              value={userInfo?.phone_number}
              onChange={(event) => {
                setPhone(event.target.value);
                setUserInfo({ ...userInfo, phone_number: event.target.value });
              }}
            />
          </div>
          <div className=" col-md-12 pb-3">
            <label htmlFor="address" className="form-label title-txt">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Ex: Sector-01 , plot-122 , Mohammadpur "
              value={userInfo?.shipping_address}
              onChange={(event) => {
                setAddress(event.target.value);
                setUserInfo({
                  ...userInfo,
                  shipping_address: event.target.value,
                });
              }}
            />
          </div>
        </div>

        {/* 2nd column */}
        <div className="col-md-6">
          <div className=" col-md-12 pb-3">
            <label htmlFor="country" className="form-label title-txt">
              Country
            </label>
            <select
              name="country"
              id="country"
              className="form-select"
              onChange={(event) => {
                setCountry(event.target.value);
                setUserInfo({ ...userInfo, country: event.target.value });
              }}
            >
              <option selected>Choose...</option>
              <option
                value="bangladesh"
                selected={userInfo?.country === "bangladesh"}
              >
                Bangladesh
              </option>
            </select>
          </div>
          <div className=" col-md-12 pb-3 d-flex">
            <div className="col-md-6 me-2">
              <label htmlFor="city" className="form-label title-txt">
                City
              </label>
              <select
                name="city"
                id="city"
                className="form-select"
                onChange={(event) => {
                  setCity(event.target.value);
                  setUserInfo({ ...userInfo, city: event.target.value });
                }}
              >
                <option selected>Choose...</option>
                <option value="dhaka" selected={userInfo?.city === "dhaka"}>
                  Dhaka
                </option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="zip-code" className="form-label title-txt">
                Zip Code
              </label>
              <input
                type="text"
                id="zip-code"
                className="form-control"
                placeholder="Ex: 7400"
                value={userInfo?.zip_code}
                onChange={(event) => {
                  setZipCode(event.target.value);
                  setUserInfo({ ...userInfo, zip_code: event.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <input
            type="button"
            className="appointment-submit-button my-5"
            value="Submit"
            onClick={submitValue}
          />
        </div>
      </form>
    </Container>
  );
};

export default CustomerInformation;
