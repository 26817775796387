import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CurrentOrder.css";
import { MdUpdate } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { GrDeliver } from "react-icons/gr";
import { GrView } from "react-icons/gr";
import axios from "axios";
import Swal from "sweetalert2";
import { RotatingLines, Triangle } from "react-loader-spinner";
import { baseurl } from "../../../../baseUrl";

const CurrentOrder = () => {
  // all order
  const [order, setOrder] = useState([]);
  // console.log(order)

  //is loading state
  const [isLoading, setIsLoading] = useState(true);

  // remaining order after order deliver
  const remainingOrder = (id) => {
    const currentOrder = order.filter((ordr) => ordr.id !== id);
    return setOrder(currentOrder);
  };

  // delivery order handler
  const orderDeliveryHandler = (orderId) => {
    const state = confirm("You want to Deliver?"); //eslint-disable-line

    if (state) {
      setIsLoading(true);
      axios
        .post(`${baseurl}/dashboard/order_delivered/`, {
          order_id: orderId,
        })
        .then(function (response) {
          // console.log(response);
          if (response?.data?.status) {
            Toast.fire({
              icon: "success",
              title: "Order Delivered Success!",
            });
            remainingOrder(orderId);
          } else {
            alert(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    background: "#000",
    color: "#FFF",
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/running_order_list/`)
      .then((res) => res.json())
      .then((data) => setOrder(data.data))
      .finally(() => setIsLoading(false));
  }, []);
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row py-3">
        <h2 className="py-3 fw-bolder text-secondary card-title">Orders</h2>

        {order.length === 0 ? (
          <div className="alert alert-primary text-center" role="alert">
            No Order Available
          </div>
        ) : (
          <div className="col table-responsive">
            <table className="table  table-bordered  rounded shadow-sm  table-hover ">
              <thead className="table-header">
                <tr className="text-center">
                  <th className="py-3" scope="col" width="50">
                    SL
                  </th>
                  <th className="py-3" scope="col">
                    Order No
                  </th>
                  <th className="py-3" scope="col">
                    Customer Name
                  </th>
                  <th className="py-3" scope="col">
                    Payable Amount
                  </th>
                  <th className="py-3" scope="col">
                    Create Date
                  </th>

                  <th className="py-3" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {order?.map((ordr, index) => (
                  <tr key={index}>
                    <th scope="row">{index}</th>
                    <td>{ordr?.order_no}</td>
                    <td>{ordr?.customer_info?.name}</td>
                    <td>{ordr?.payable_amount}</td>
                    <td>{ordr?.created_at}</td>

                    <td className=" text-center">
                      <Link to={`/dashboard/orderDetailsView/${ordr.id}`}>
                        <GrView className="text-success fs-5"></GrView>
                      </Link>

                      {/* <Link to={`/dashboard/orderDeliver/${ordr.id}`}>
                                            <GrDeliver onClick={orderDeliveryHandler(ordr.id)} className='mx-2 fs-4'></GrDeliver>
                                        </Link> */}
                      <GrDeliver
                        role={"button"}
                        onClick={() => orderDeliveryHandler(ordr.id)}
                        className="mx-2 fs-4"
                      ></GrDeliver>

                      {/* <TiDelete className=' fs-3 text-danger' role={"button"}
                                            onClick={() => handleDeleteOrder(ordr?.id)}></TiDelete> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentOrder;
