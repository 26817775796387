import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import "./ModalPopup.css";
import { baseurl } from "../../../baseUrl";
const ModalPopup = ({ slug }) => {
  const [video, setVideo] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    fetch(`${baseurl}/dashboard/tailor_me_now_product_details/${slug}/`)
      .then((res) => res.json())
      .then((data) => setVideo(data.data));
  }, [slug]);
  return (
    <>
      <button className="add-To-Cart-Btn-Card me-2" onClick={handleShow}>
        Tailor Me Now
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Tailor Me Now</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="p-0">
          <ReactPlayer
            url={video.product_video_url}
            width="640"
            height="auto"
            controls={true}
          />
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Link to={`/tailorMeNowSize/${slug}`}>
            <button className="add-To-Cart-Btn-Card" onClick={handleClose}>
              Next
            </button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPopup;
