import React from "react";
import confirmImg from "../../../images/confirm-img.svg";
import "./Confirmation.css";
import { IoTimeOutline } from "react-icons/io5";
import { FaTruck } from "react-icons/fa";
import useCart from "../../../Hooks/useCart";
import { Link } from "react-router-dom";

const Confirmation = ({ terms, setTerms }) => {
  // cart context api
  const cartInfo = useCart();
  const { items } = cartInfo;
  return (
    <div className="container-fluid">
      <div className="container mx-auto">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6  p-3">
            <div className="p-3 text-center bg-white ">
              <img src={confirmImg} alt="" className="img-fluid mx-auto" />
              <h3 className="pt-5 order-process-all-title-txt py-3 lh-sm">
                Complete Payment and Confirm Order
              </h3>
              <p className="pb-3 order-process-sub-title-txt  ">
                Your order have been confirmed, please wait and track your order
              </p>
              {/* <button type="submit" className=" go-track-btn ">Go to track page</button> */}
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6  p-3">
            <div className="p-3  bg-white">
              <div className="d-flex align-items-center bg-white ">
                <p className="pe-2 order-process-sub-title-txt">
                  <IoTimeOutline className="pe-2 fs-4"></IoTimeOutline>10 days
                  delivery
                </p>
                <p>
                  <FaTruck className="pe-2 fs-4 order-process-sub-title-txt"></FaTruck>
                  Paperfly
                </p>
              </div>
              <div className="py-3 order-process-sub-title-txt">
                {/* <p className='p-0 m-0'>Tanjim Squard T-shirt</p>
                                <p className='p-0 m-0 text-secondary fs-6'> 2 x BDT 1,024.00</p>
                                <p className='p-0 m-0'>Tanjim Squard T-shirt</p>
                                <p className='p-0 m-0 text-secondary fs-6'> 2 x BDT 1,024.00</p> */}
              </div>
              <div className="table-responsive">
                <table className="table ">
                  <tbody className="order-process-sub-title-txt">
                    <tr>
                      <td>Product Total Price</td>
                      <td>{items?.product_order?.grand_total ?? ""}</td>
                    </tr>

                    <tr>
                      <td>Shipping Cost </td>
                      <td>
                        {items?.product_order?.price?.delivery_charge ?? ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Discount Amount</td>
                      <td>{items?.product_order?.discount_amount ?? ""}</td>
                    </tr>
                    <tr>
                      <td>Packaging</td>
                      <td>
                        {items?.product_order?.price?.packaging_price ?? ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Payable</td>
                      <td>{items?.product_order?.payable_amount ?? ""}</td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td>Shipping Address</td>
                      <td>
                        {items?.product_order?.customer_info
                          ?.shipping_address ?? ""}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div
                className="d-flex align-items-center gap-2 ms-2"
                style={{ width: "fit-content" }}
              >
                <input
                  type="checkbox"
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                  style={{ zoom: 1.2 }}
                />
                <span>
                  Agree to{" "}
                  <Link
                    to="/terms_conditions"
                    className="text-decoration-none text-danger"
                  >
                    Terms & Conditions*
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
