import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import Navigation from "../../Pages/Shared/Navigation/Navigation";
import CatagoryCard from "../Home/CatagoryCard/CatagoryCard";
import Footers from "../Shared/Footers/Footers";
import { baseurl } from "../../baseUrl";

const AllProductCatagory = () => {
  const [productAllCat, setProductAllCat] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const cat_product_view = (id) => {
    //console.log(id)
  };
  useEffect(() => {
    setIsLoading(true);
    fetch(`${baseurl}/dashboard/category_wise_product/`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProductAllCat(data);
      })
      .finally(() => setIsLoading(false));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center py-5">
        <RotatingLines
          strokeColor="#d4b16c"
          strokeWidth="3"
          animationDuration="0.75"
          width="50"
          visible={true}
        />
      </div>
    );
  }
  return (
    <>
      {/* <Navigation></Navigation> */}
      <div className="container">
        <h2 className="monthly pt-5 text-uppercase">Categories</h2>
        <hr className="horizontal-related " style={{ height: "3px" }} />
        <div className="text-center py-3">
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4 pb-5">
            {/* {
                        productCat?.data?.map(cat => < CatagoryCard

                            key={cat.slug}
                            cat={cat}
                        >
                        </CatagoryCard>)
                    } */}

            {productAllCat?.data?.map((cat, index) => {
              const categoryProps = {
                ...(index % 2 === 0 && { className: "mt-lg-4" }),
              };
              return (
                <CatagoryCard
                  {...categoryProps}
                  key={cat.slug}
                  cat_product_view={cat_product_view}
                  cat={cat}
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* <Footers></Footers> */}
    </>
  );
};

export default AllProductCatagory;
